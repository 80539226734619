// export const BASE_URL = "http://127.0.0.1:8000/api/";
export const BASE_URL = "https://turfapi.atjoindemowebsite.in.net/public/api/";
// export const BASE_URL = "https://bnbturfapi.atjoindemowebsite.in.net/public/api/";
// export const BASE_URL = process.env.REACT_APP_BASE_URL;

// export const IMAGE_BASE_URL = "http://127.0.0.1:8000/storage/";
export const IMAGE_BASE_URL = "https://turfapi.atjoindemowebsite.in.net/public/storage/";
// export const IMAGE_BASE_URL = "https://bnbturfapi.atjoindemowebsite.in.net/public/storage/";
// export const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

export const PHONEPE_PLESK_URL = "https://sndlmsapi.atopd.in/api/";
// export const PHONEPE_PLESK_URL = process.env.REACT_APP_PHONEPE_PLESK_URL;

// User Credentials Start
export const USER_ID = JSON.parse(localStorage.getItem("loggedInUser"));
export const ADMIN_DATA = JSON.parse(localStorage.getItem("adminLogin"));
// User Credentials End

// Payment Constants Start
export const ONLINE_ORDER_DETAILS = JSON.parse(
  localStorage.getItem("OnlineOrderDetails")
);
export const PAYMENT_DETAILS = JSON.parse(
  localStorage.getItem("PaymentDetails")
);
export const BOOKING_DETAILS = JSON.parse(
  localStorage.getItem("BookingDetails")
);
export const CONTACT_NUMBER = JSON.parse(
  localStorage.getItem("contact_number")
);
export const COURT_ID = JSON.parse(localStorage.getItem("courtId"));

export const PAYMENT_RESPONSE = JSON.parse(
  localStorage.getItem("paymentResponse")
);
export const XVERIFY = localStorage.getItem("xVerify");

// Payment Constants Start
// ATJOIN LIVE ENVIRONMENT START
// export const SALT_KEY = '0d8b9f31-5d27-48a7-9405-d83f80d2944a';
// export const MERCHANT_ID = 'ATJOINONLINE';
// export const API_URL = `${PHONEPE_PLESK_URL}Employee/phonepe`;
// ATJOIN LIVE ENVIRONMENT END

// SANDBOX ENVIRONMENT START
export const SALT_KEY = "099eb0cd-02cf-4e2a-8aca-3e6c6aff0399";
export const SALT_INDEX = 1;
export const MERCHANT_ID = "PGTESTPAYUAT";
export const API_URL =
  "https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay";
export const API_URL_STATUS =
  "https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/status";
// SANDBOX ENVIRONMENT END
// Payment Constants End
