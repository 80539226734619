export const formattedTime = (time) => {
  if (time) {
    const [hours, minutes] = time.split(":");
    const timeObj = new Date();
    timeObj.setHours(hours);
    timeObj.setMinutes(minutes);
    const ampm = timeObj.getHours() >= 12 ? "PM" : "AM";
    let hours12 = timeObj.getHours() % 12;
    hours12 = hours12 ? hours12 : 12; // convert 0 to 12
    return `${hours12}:${minutes} ${ampm}`;
  }
};

export const HTMLRenderer = (htmlString) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
};

export const getTodaysDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
