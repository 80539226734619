import React, { useEffect, useState } from "react";
import { BreadCrumb } from "../../common/BreadCrumb";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import {
  contactUsMessageSendSuccessfully,
  toastTimeStamp,
} from "../../../constants/ResponseConstants";
import ToastPopup from "../../modals/ToastPopup";
import { getAdmin } from "../../../services/ApiServices";
import { Link } from "react-router-dom";
import { StringToHTML } from "../../../services/ShortFunctions";

export default function ContactUs() {
  const [lData, setlData] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    getAdminData();
  }, []);

  const {
    register,
    reset,
    formState: { errors },
  } = useForm();
  // Toast State Start
  const [showToast, setShowToast] = useState(false);
  const [tMessage, setTMessage] = useState();
  const [tVariant, setTVariant] = useState();
  // Toast State End
  const onSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_crzebum",
        "template_1w6pkzz",
        e.target,
        "1jD_polfkUBc9I_c1"
      )
      .then(
        (result) => {
          if (result.status === 200 && result.text === "OK") {
            reset();
            // Toast Start
            setShowToast(true);
            setTMessage("<b>" + contactUsMessageSendSuccessfully + "</b>.");
            setTVariant("success");
            setTimeout(() => setShowToast(false), toastTimeStamp);
            // Toast End
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const [orgData, setOrgData] = useState([]);

  const getAdminData = async () => {
    const response = await getAdmin(1).catch(console.error);
    setOrgData(response.data);
    console.log("response.data: " + response.data);
  };

  return (
    <>
      {showToast && (
        <ToastPopup isShow={showToast} variant={tVariant} message={tMessage} />
      )}
      <div>
        <BreadCrumb name="Contact Us" />
        <div className="contact-area my-5">
          <div className="container">
            <div className="row g-4">
              <div className="col-md-5 col-xl-5">
                <div className="office-information">
                  <h2>Talk To With Our Best Consultant.</h2>
                  <div className="single-information">
                    <div className="bg-shape">
                      <img src="/assets/img/info-shape.png" alt="MyTurf" />
                    </div>
                    <div className="icon">
                      <i>
                        <img
                          src="/assets/img/icons/location.png"
                          alt="MyTurf"
                        />
                      </i>
                    </div>
                    <div className="info-cnt">
                      <h6>Location</h6>
                      <p>
                        {orgData.address_line}
                        {orgData.address_line && ', '}
                        {orgData.city}{orgData.city && ', '}
                        {orgData.district}{orgData.district && ', '}
                        {orgData.state}{' - '}{orgData.pin_code}
                      </p>
                    </div>
                  </div>
                  <div className="single-information">
                    <div className="bg-shape">
                      <img src="/assets/img/info-shape.png" alt="MyTurf" />
                    </div>
                    <div className="icon">
                      <i>
                        <img src="/assets/img/icons/mobile.png" alt="MyTurf" />
                      </i>
                    </div>
                    <div className="info-cnt">
                      <h6>Phone</h6>
                      <Link to={`tel:${orgData.contact_number_one}`}>
                        +91 {orgData.contact_number_one}
                      </Link>
                      <Link to={`tel:${orgData.contact_number_two}`}>
                        +91 {orgData.contact_number_two}
                      </Link>
                    </div>
                  </div>
                  <div className="single-information">
                    <div className="bg-shape">
                      <img src="/assets/img/info-shape.png" alt="MyTurf" />
                    </div>
                    <div className="icon">
                      <i>
                        <img
                          src="/assets/img/icons/envelope.png"
                          alt="MyTurf"
                        />
                      </i>
                    </div>
                    <div className="info-cnt">
                      <h6>Email</h6>
                      <Link to={`mailto:${orgData.email_id}`}>
                        <span className="__cf_email__">{orgData.email_id}</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7 col-xl-7">
                <div className="contact-form">
                  <h3>Write A Message</h3>
                  <form onSubmit={onSubmit}>
                    <input
                      type="text"
                      placeholder="Your Full Name"
                      name="name"
                      {...register("name", { required: true })}
                      className="mb-2"
                    />
                    {errors.name && (
                      <p className="text-danger fw-bold small">
                        * Name is required.
                      </p>
                    )}
                    <input
                      type="email"
                      placeholder="Your Email"
                      name="email"
                      {...register("email", { required: true })}
                      className="mb-2"
                    />
                    {errors.email && (
                      <p className="text-danger fw-bold small">
                        * Email ID is required.
                      </p>
                    )}
                    <input
                      type="text"
                      placeholder="Your Phone"
                      name="phone"
                      {...register("phone", { required: true })}
                      className="mb-2"
                    />
                    {errors.phone && (
                      <p className="text-danger fw-bold small">
                        * Contact number is required.
                      </p>
                    )}
                    <input
                      type="text"
                      placeholder="Subject"
                      name="subject"
                      {...register("subject", { required: true })}
                      className="mb-2"
                    />
                    {errors.subject && (
                      <p className="text-danger fw-bold small">
                        * Subject is required.
                      </p>
                    )}
                    <textarea
                      cols="30"
                      rows="10"
                      placeholder="Write Message"
                      name="message"
                      {...register("message", { required: true })}
                      className="mb-2"
                    ></textarea>
                    {errors.message && (
                      <p className="text-danger fw-bold small">
                        * Message is required.
                      </p>
                    )}
                    <div className="button--wrap">
                      <button
                        type="submit"
                        id="msg"
                        className="eg-btn btn--primary golf-btn mx-auto pe-4"
                        style={{ zIndex: "auto" }}
                      >
                        <i className="bi bi-send me-2 ms-0"></i>Submit Now
                        {/*<i className="bi bi-arrow-right"></i> */}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="google-map">
          <div className="container-fluid g-0">
            <div className="map">
              <div className="row g-0">
                <div className="col">
                  {/* <iframe title='Map'
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d455500.20527088636!2d75.19959032023928!3d26.884594416311536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396c4adf4c57e281%3A0xce1c63a0cf22e09!2sJaipur%2C%20Rajasthan!5e0!3m2!1sen!2sin!4v1691682694684!5m2!1sen!2sin"></iframe> */}
                  {StringToHTML(orgData.map)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
