import React, { useEffect, useState } from "react";
import ToastPopup from "../../modals/ToastPopup";
import { useForm } from "react-hook-form";
import {
  bookingSuccess,
  toastTimeStamp,
} from "../../../constants/ResponseConstants";
import {
  createBookings,
  getCourts,
  getPrice,
  getSports,
} from "../../../services/ApiServices";
import { useNavigate } from "react-router-dom";
import Loader from "../../common/Loader";
import { ADMIN_DATA } from "../../../constants/DataConstants";
import ReactDatePicker from "react-datepicker";

function BookNow() {
  useEffect(() => {
    window.scrollTo(0, 0);
    loadSports();
    loadCourts();
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [sports, setSports] = useState(false);
  const [courts, setCourts] = useState();
  const [duration, setDuration] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());

  // Toast State Start
  const [showToast, setShowToast] = useState(false);
  const [tMessage, setTMessage] = useState();
  const [tVariant, setTVariant] = useState();
  // Toast State End

  const loadSports = async () => {
    setIsLoading(true);
    const response = await getSports().catch(console.error);
    setSports(response.data);
    setIsLoading(false);
  };
  const loadCourts = async () => {
    setIsLoading(true);
    const response = await getCourts().catch(console.error);
    setCourts(response.data);
    setIsLoading(false);
  };

  const manageDuration = (flag) =>
    setDuration(flag === "D" && duration > 1 ? duration - 1 : duration + 1);

  const onSubmit = async (bData) => {
    let sTime =
      (startTime.getHours() < 10
        ? "0" + startTime.getHours()
        : startTime.getHours()) +
      ":" +
      (startTime.getMinutes() < 10
        ? "0" + startTime.getMinutes()
        : startTime.getMinutes());
    const data = {
      BookingDate: getFormattedDate(),
      StartTime: sTime,
      ...bData,
    };

    data.EndTime = calculateEndTime(data);
    // getPrice code start
    const getPriceData = {
      BKStart_time: data.StartTime + ":00",
      BKEnd_Time: data.EndTime + ":00",
      CourtId: data.CourtId,
      BookingDate: data.BookingDate,
    };
    try {
      const response = await getPrice(getPriceData);
      let finalTotalPrice = 0,
        baseTotalPrice = 0;
      for (let index = 0; index < response.data.length; index++) {
        finalTotalPrice += Number(response.data[index]["FinalPrice"]);
        baseTotalPrice += Number(response.data[index]["BasePrice"]);
      }
      if (finalTotalPrice !== 0 && baseTotalPrice !== 0) {
        const newData = { baseTotalPrice, finalTotalPrice, ...data };
        const bookingData = {
          UserId: ADMIN_DATA.id,
          CourtId: newData.CourtId,
          SportId: newData.SportId,
          BookingDate: newData.BookingDate,
          StartTime: newData.StartTime,
          EndTime: newData.EndTime,
          PaymentId: ADMIN_DATA.id,
          StatusId: ADMIN_DATA.id,
          Description: newData.Description,
          NameCustomer: newData.NameCustomer,
          ContactCustomer: newData.ContactCustomer,
        };
        const resBooking = await createBookings(bookingData).catch(
          console.error
        );
        if (resBooking.status === 1) {
          setIsLoading(false);
          setShowToast(true);
          setTMessage("<b>" + bookingSuccess + "</b>.");
          setTVariant("success");
          setTimeout(() => setShowToast(false), toastTimeStamp);
          window.location.reload();
        }
        // navigate("/booking-status");
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response) {
        // Toast Start
        setShowToast(true);
        setTMessage("<b>" + error.response.data.message.errorInfo[2] + "</b>.");
        setTVariant("danger");
        setTimeout(() => setShowToast(false), toastTimeStamp);
        // Toast End
      } else if (error.request) {
        console.error("Network Error: ", error.request);
      } else {
        console.error("Error: ", error.message);
      }
    }
    // getPrice code end
  };

  const calculateEndTime = (data) => {
    const padZero = (num) => (num < 10 ? "0" : "") + num;
    const timeParts = (duration + ":00").split(":");
    const startParts = data.StartTime.split(":");
    const totalMins =
      parseInt(startParts[0]) * 60 +
      parseInt(startParts[1]) +
      parseInt(timeParts[0]) * 60 +
      parseInt(timeParts[1]);
    const hours = Math.floor(totalMins / 60) % 24;
    const minutes = totalMins % 60;
    return `${padZero(hours)}:${padZero(minutes)}`;
  };

  const getFormattedDate = () => {
    const day = ("0" + startDate.getDate()).slice(-2); // Get day of the month
    const month = ("0" + (startDate.getMonth() + 1)).slice(-2); // Get month (adding 1 since January is 0)
    const year = startDate.getFullYear(); // Get year
    return `${year}-${month}-${day}`; // Format date as 'YYYY-MM-DD'
  };

  return (
    <>
      {isLoading && <Loader />}
      {showToast && (
        <ToastPopup isShow={showToast} variant={tVariant} message={tMessage} />
      )}

      <div className="office-information mb-lg-5">
        <div className="contact-form admin-form">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <label className="col-sm-3 col-form-label">
                Customer Name<span className="text-danger">*</span>:
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  placeholder="Customer Name"
                  {...register("NameCustomer", { required: true })}
                />
                {errors.NameCustomer && (
                  <span className="text-danger fw-bold">
                    Customer name is required
                  </span>
                )}
              </div>
            </div>
            <div className="row">
              <label className="col-sm-3 col-form-label">
                Contact Number<span className="text-danger">*</span>:
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  placeholder="+91 000 000 0000"
                  {...register("ContactCustomer", { required: true })}
                />
                {errors.ContactCustomer && (
                  <span className="text-danger fw-bold">
                    Contact Number is required
                  </span>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-3 col-form-label">
                Select Sport <span className="text-danger">*</span>:
              </label>
              <div className="col-sm-9">
                <select
                  {...register("SportId", { required: true })}
                  className="form-select mb-0"
                >
                  {sports &&
                    sports.map((item, i) => (
                      <option key={i} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
              {errors.SportId && (
                <span className="text-danger fw-bold small">
                  {errors.SportId.message}
                </span>
              )}
            </div>
            <div className="row mb-3">
              <label className="col-sm-3 col-form-label">
                Select Turf <span className="text-danger">*</span>:
              </label>
              <div className="col-sm-9">
                <select
                  {...register("court_name", { required: "Select Turf" })}
                  className="form-select mb-0"
                >
                  {courts &&
                    courts.map((item, i) => (
                      <option key={i} value={item.id}>
                        {item.court_name}
                      </option>
                    ))}
                </select>
              </div>
              {errors.court_name && (
                <span className="text-danger fw-bold small">
                  {errors.court_name.message}
                </span>
              )}
            </div>
            <div className="row mb-3">
              <label className="col-sm-3 col-form-label">
                Select Date <span className="text-danger fw-bold">*</span>:
              </label>
              <div className="col-sm-9">
                <ReactDatePicker
                  selected={startDate}
                  minDate={new Date()}
                  dateFormat={"yyyy-MM-dd"}
                  className="mb-0"
                  onChange={(date) => setStartDate(date)}
                />
                {errors.BookingDate && (
                  <p className="text-warning small">* Select Date</p>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-3 col-form-label">
                Start Time <span className="text-danger">*</span>:
              </label>
              <div className="col-sm-9">
                <ReactDatePicker
                  className="mb-0"
                  selected={startTime}
                  onChange={(date) => setStartTime(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={5}
                  dateFormat="hh:mm aa"
                />
                {errors.start_time && (
                  <p className="text-warning small">* Select Start Time</p>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-3 col-form-label">
                Duration <span className="text-danger">*</span>:
              </label>
              <div className="col-sm-9">
                <i
                  onClick={() => manageDuration("D")}
                  className={
                    duration > 1
                      ? "fas fa-minus mx-4 bg-danger p-2 text-white rounded-circle"
                      : "fas fa-minus mx-4 bg-secondary p-2 text-white rounded-circle"
                  }
                ></i>
                <span>{duration} Hr</span>
                <i
                  onClick={() => manageDuration("I")}
                  className="fas fa-plus mx-4 bg-success p-2 text-white rounded-circle"
                ></i>
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-3 col-form-label">Description:</label>
              <div className="col-sm-9">
                <input
                  type="text"
                  placeholder="Description"
                  {...register("Description")}
                />
                {errors.Description && (
                  <span className="text-danger fw-bold">
                    Something went wrong.
                  </span>
                )}
              </div>
            </div>
            {/* <div className="row mb-3">
              <label className="col-sm-3 col-form-label">Customer Address:</label>
              <div className="col-sm-9">
                <input type="text" placeholder="Customer Address" {...register("customer_address")} />
                {errors.customer_address && <span className='text-danger fw-bold'>Customer Address is required</span>}
              </div>
            </div> */}

            <div className="button--wrap mt-0">
              <button
                type="submit"
                className="eg-btn btn--primary golf-btn mx-auto"
              >
                Book
                <i className="bi bi-arrow-right"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default BookNow;
