import axios from "axios";
import {
  API_URL,
  API_URL_STATUS,
  BASE_URL,
  PHONEPE_PLESK_URL,
  SALT_INDEX,
  SALT_KEY,
} from "../../constants/DataConstants";
import { SHA256 } from "crypto-js";

const ax = axios.create({ baseURL: BASE_URL });

// Payment API Live
// export const phonePePayment = async (data, xVerify) => {
//   var payData = { request: data, xVerify: xVerify };
//   return ax.post(`${PHONEPE_PLESK_URL}Employee/phonepe`, payData);
// };

// export const checkPaymentStatus = async (
//     xVerify,
//     merchantId,
//     merchantTransactionId
// ) => {
//  var newData = {
//   xVerify: xVerify,
//   merchantId: merchantId,
//   merchantTransactionId: merchantTransactionId,
//  };
//  return ax.post(
//      `${PHONEPE_PLESK_URL}Employee/check-payment-status`,
//      newData
//  );
// };

export const phonePePayment = async (requestData, xVerify) => {
  const data = { request: requestData };
  const options = {
    method: "POST",
    url: API_URL,
    headers: {
      "Content-Type": "application/json",
      "X-VERIFY": xVerify,
    },
    data,
  };

  const { data: response } = await ax.request(options);
  return response;
};

export const checkPaymentStatus = async (xVerify, merchantId, txnId) => {
  const checksum =
    SHA256(`/pg/v1/status/${merchantId}/${txnId}` + SALT_KEY) +
    "###" +
    SALT_INDEX;
  const config = {
    method: "GET",
    url: `${API_URL_STATUS}/${merchantId}/${txnId}`,
    headers: {
      "Content-Type": "application/json",
      "X-VERIFY": checksum,
      "X-MERCHANT-ID": merchantId,
    },
  };

  const { data } = await axios.request(config);
  return data;
};

// Manage Admin Profile Start
export const adminLogin = async (data) => {
  let config = {
    method: "post",
    url: `admin/login`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: data,
  };
  const response = await ax.request(config);
  return response.data;
};

export const getAdmins = async () => {
  const response = await ax.get(`admin/get-admins`);
  return response.data;
};

export const getAdmin = async (id) => {
  const response = await ax.get(`admin/get-admin/${id}`);
  return response.data;
};

export const updateAdmin = async (id, data) => {
  const response = await ax.post(`admin/update-admin/${id}`, data, {
    headers: { "Content-Type": "appliction/json" },
  });
  return response.data;
};

export const updateLogo = async (id, data) => {
  const response = await ax.post(`admin/update-logo/${id}`, data, {
    headers: { "Content-Type": "appliction/json" },
  });
  return response.data;
};

export const updateAdminPass = async (id, data) => {
  const response = await ax.patch(`admin/update-password/${id}`, data, {
    headers: { "Content-Type": "appliction/json" },
  });
  return response.data;
};
// Manage Admin Profile End

// Get Count Start
export const getCount = async () => {
  const response = await ax.get(`admin/get-counts`);
  return response.data;
};
// Get Count End

// Manage Users Start
export const getUsers = async () => {
  // const config = {
  //   headers: {
  //     // Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiYmM2ZWUwNWQzYmNlNTVlNjI0OTM4NjMxZDAwNTBiYmI0M2MzNDVjMzk0OGY1NmFmOTU3YjUwZmM4MWRiNmY4MGUzMmUwYWI0NjBlODkyMzIiLCJpYXQiOjE2OTA2NDI5NzQuMTgzNzc1LCJuYmYiOjE2OTA2NDI5NzQuMTgzNzc4LCJleHAiOjE3MjIyNjUzNzQuMTcxNjE4LCJzdWIiOiIyIiwic2NvcGVzIjpbXX0.sPFBkfrvBAKtaswHSadEdAnmgkK3FhvJPxcyLcxCpyZ67SQ6Nzs0z3u9jhdYQZGfVplx-7ScCAcb4OlOoReFne6R56YKGYZ7ON9BF_7Wch45iNxRSxDet2D_AIco0zKfx7Vsl3TgZdzI5QchP44XS_jSAobrM0AaTlWhia9szZZb96GsCABo_o16uecdiCGf7fx2EzUa_svh011yODlKmQyg5ltweGAtsiT09znClrIDSAkmDHQS9aFDSY1rjEKT28Wqd2FIuN59HUbVMpNTWkJ0psEkpSA0uw7yLBqgaExBm8zecu-rXwvHVnfhyopQOBqa7ZHwUtHMuJotAM674xan6RKVqDqpl4W0cH6AOg_svLGaYLACGbuplQC-Hcd0mgoTNPeRyaEpx7iSbDZjgMqWP2t47x8qmOqdcxjDfnngBtprOpvQmW26g0wRjbq5tL9z4C7y0NrjAl5S1e3MdULTsbkke2jQ2GhrKxj1xpak8oZ9ELCgSngEyv4fvtM7QoZI6GqLGGuqFnS1ogRDDIp4ILFBKXduSrBAZCUt41ERg8wMf8T5nUnPbUVDb_Lv4bo9RmWy_anoMwUaJHrNzcqn2EBejg9yMBKx_qFuzS_OuRLs6gqvwqSuhRPKGvT9qdnkDQ_nIdvp5vWo6jKZOtKQw8XmrNvuqrAwEv79640',
  //     // 'Content-Type': 'application/x-www-form-urlencoded',
  //     // 'Content-Type': 'multipart/form-data',
  //     'Content-type': 'application/json; charset=UTF-8'
  //   }
  // }
  const response = await ax.get(`get-users`);
  return response.data;
};

export const getSingleUser = async (userId) => {
  const { data } = await ax.get(`get-user/${userId}`);
  return data;
};

export const updateUser = async (id, data) => {
  let config = {
    method: "post",
    url: `update-user/${id}`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: { _method: "PUT", ...data },
  };
  const response = await ax.request(config);
  return response.data;
};

export const updateUserStatus = async (data, id) => {
  let config = {
    method: "post",
    url: `update-user-status/${id}`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: { _method: "PATCH", ...data },
  };
  const response = await ax.request(config);
  return response.data;
};

export const getSinglePayament = async (id) => {
  const response = await ax.get(`admin/get-payment/${id}`);
  return response.data;
};

export const sendOTP = async (data) => {
  let config = {
    method: "post",
    url: `user/send-otp`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: data,
  };
  const response = await ax.request(config);
  return response?.data;
};

export const existUserSendOTP = async (data) => {
  const response = await ax.post(`user/login`, data);
  return response?.data;
};

export const createAccount = async (data) => {
  let config = {
    method: "post",
    url: `user/create`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: data,
  };
  const response = await ax.request(config);
  return response?.data;
};
// Manage Users End

// Manage Booking & Payment Start
export const createBookings = async (data) => {
  let config = {
    method: "post",
    url: `admin/create-booking`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: data,
  };
  const response = await ax.request(config);
  return response.data;
};

export const createPayment = async (data) => {
  let config = {
    method: "post",
    url: `admin/create-payment`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: data,
  };
  const response = await ax.request(config);
  return response.data;
};

export const getBookings = async (data) => {
  let { CourtID, DateFrom, DateTo, UserID } = data;
  const url = `admin/get-booking?CourtID=${CourtID}&DateFrom=${DateFrom}&DateTo=${DateTo}&UserID=${UserID}`;
  const response = await ax.get(url);
  return response.data;
};
// Manage Booking & Payment End

// Get Price Start
export const getPrice = async (data) => {
  let { BKStart_time, BKEnd_Time, CourtId, BookingDate } = data;
  let config = {
    method: "get",
    url: `admin/get-price?BKStart_time=${BKStart_time}&BKEnd_Time=${BKEnd_Time}&CourtId=${CourtId}&BookingDate=${BookingDate}`,
  };
  const response = await ax.request(config);
  return response.data;
};
// Get Price End

// Manage Turf Start
export const createCourt = async (data) => {
  let config = {
    method: "post",
    url: `admin/create-court`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: data,
  };
  const response = await ax.request(config);
  return response.data;
};

export const getCourts = async () => {
  const response = await ax.get(`admin/get-courts`);
  return response?.data;
};

export const getSingleCourt = async (id) => {
  const response = await ax.get(`admin/get-court/${id}`);
  return response?.data;
};

export const updateCourt = async (courtData, courtId) => {
  const config = {
    method: "post",
    url: `admin/update-court/${courtId}`,
    headers: { "Content-Type": "multipart/form-data" },
    data: courtData,
  };

  try {
    const { data } = await ax.request(config);
    return data;
  } catch (error) {
    console.log("🚀 ~ updateCourt ~ error:", error);
  }
};

export const updateCourtStatus = async (data, id) => {
  let config = {
    method: "post",
    url: `admin/update-court-status/${id}`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: { _method: "PATCH", ...data },
  };
  try {
    const response = await ax.request(config);
    return response.data;
  } catch (error) {
    console.log("🚀 ~ updateCourtStatus ~ error:", error);
  }
};

export const deleteCourt = async (id) => {
  let config = {
    method: "post",
    url: `admin/remove-court/${id}`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: { _method: "DELETE" },
  };
  const response = await ax.request(config);
  return response.data;
};
// Manage Turf End

// Manage Service Start
export const createService = async (data) => {
  const response = await ax.post(`admin/create-service`, data);
  return response.data;
};

export const getServices = async () => {
  let config = { method: "get", url: `admin/get-services` };
  const response = await ax.request(config);
  return response.data;
};

export const getSingleServices = async (id) => {
  const response = await ax.get(`admin/get-service/${id}`);
  return response.data;
};

export const updateService = async (data, id) => {
  const response = await ax.post(`admin/update-service/${id}`, data, {
    headers: { "Content-Type": "appliction/json" },
  });
  return response.data;
};

export const deleteService = async (id) => {
  let config = {
    method: "post",
    url: `admin/remove-service/${id}`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: { _method: "DELETE" },
  };
  const response = await ax.request(config);
  return response.data;
};
// Manage Service End

// Manage Time Slots Start
export const getTimeSlots = async () => {
  const response = await ax.get(`admin/get-slots`);
  return response.data;
};

export const getSingleTimeSlot = async (id) => {
  const response = await ax.get(`admin/get-slot/${id}`);
  return response.data;
};

export const createTimeSlot = async (data) => {
  let config = {
    method: "post",
    url: `admin/create-slot`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: data,
  };
  const response = await ax.request(config);
  return response.data;
};

export const updateTimeSlot = async (data, id) => {
  let config = {
    method: "post",
    url: `admin/update-slot/${id}`,
    headers: { "Content-Type": "multipart/form-data" },
    data: data,
  };
  const response = await ax.request(config);
  return response.data;
};

export const deleteTimeSlot = async (id) => {
  let config = {
    method: "post",
    url: `admin/remove-slot/${id}`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: { _method: "DELETE" },
  };
  const response = await ax.request(config);
  return response.data;
};
// Manage Time Slots End

// Manage Time Slots Start
export const getTimeSlotsPricing = async () => {
  const response = await ax.get(`admin/get-slots-pricing`);
  return response.data;
};

export const getSingleTimeSlotPricing = async (id) => {
  const response = await ax.get(`admin/get-slot-pricing/${id}`);
  return response.data;
};

export const getTimeSlotsByCourtId = async (id) => {
  try {
    const response = await ax.get(`admin/get-slots-by-court-id/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getTimeSlotPricingsByCourtId = async (cid, tspid) => {
  try {
    const response = await ax.get(
      `admin/get-slot-pricing-by-court-id/${cid}/${tspid}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const createTimeSlotPricing = async (data) => {
  for (const iterator of data) {
  }
  const response = await ax.post(`admin/create-slot-pricing`, data);
  return response.data;
};

export const updateTimeSlotPricing = async (data, id) => {
  let config = {
    method: "post",
    url: `admin/update-slot-pricing/${id}`,
    headers: { "Content-Type": "multipart/form-data" },
    data: data,
  };
  const response = await ax.request(config);

  return response.data;
};

export const updateTimeSlotPricingStatus = async (data, id) => {
  let config = {
    method: "patch",
    url: `admin/update-slot-pricing-status/${id}`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: data,
  };
  const response = await ax.request(config);

  return response.data;
};

export const deleteTimeSlotPricing = async (id) => {
  let config = {
    method: "post",
    url: `admin/remove-slot-pricing/${id}`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: { _method: "DELETE" },
  };
  const response = await ax.request(config);
  return response.data;
};
// Manage Time Slots End

// Get Offers Start
export const getOffersData = async (id) => {
  const response = await ax.get(`admin/get-offers/${id}`);
  return response.data;
};
// Get Offers End

// Manage Sports End
export const createSport = async (data) => {
  const response = await ax.post(`admin/create-sport`, data);
  return response.data;
};

export const getSports = async () => {
  const response = await ax.get(`admin/get-sports`);
  return response?.data;
};

export const getSingleSport = async (id) => {
  const response = await ax.get(`admin/get-sport/${id}`);
  return response.data;
};

export const updateSport = async (data, id) => {
  const response = await ax.post(`admin/update-sport/${id}`, data);
  return response.data;
};

export const deleteSport = async (id) => {
  let config = {
    method: "post",
    url: `admin/remove-sport/${id}`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: { _method: "DELETE" },
  };
  const response = await ax.request(config);
  return response.data;
};
// Manage Sports End

// Manage Sports End
export const createSportCourt = async (data) => {
  const response = await ax.post(`admin/create-court-sport`, data);
  return response.data;
};

export const getSportsCourts = async () => {
  const response = await ax.get(`admin/get-courts-sports`);
  return response?.data;
};

export const getSportWiseCourts = async (sportId, actionType) => {
  const { data: courtData } = await ax.get(
    `admin/get-sport-wise-courts/${sportId}/${actionType}`
  );
  return courtData;
};

export const geCourtWiseSports = async (turfId, actionType) => {
  const { data: sportData } = await ax.get(
    `admin/get-court-wise-sports/${turfId}/${actionType}`
  );
  return sportData;
};

export const updateSportCourt = async (data, id) => {
  const response = await ax.post(`admin/update-court-sport/${id}`, data);
  return response.data;
};

export const deleteSportCourt = async (id) => {
  let config = {
    method: "post",
    url: `admin/remove-court-sport/${id}`,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    data: { _method: "DELETE" },
  };
  const response = await ax.request(config);
  return response.data;
};
// Manage Sports End

// Local JSON Start
export const homeJSON = async () => {
  const response = await ax.get(process.env.PUBLIC_URL + "json/home.json");
  return response.data;
};
// Local JSON End

// Pin Code Start
export const pinCode = async (pc) => {
  const response = await ax.get(`https://api.postalpincode.in/pincode/${pc}`);
  return response.data;
};
// Pin Code End

// Offer Duration Start
export const getOfferDuration = async () => {
  const response = await ax.get(`admin/get-offer-duration`);
  return response.data;
};

export const setOfferDuration = async (t) => {
  const response = await ax.get(`admin/set-offer-duration/${t}`);
  return response.data;
};
// Offer Duration End
