import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendOTP } from "../../../services/ApiServices";
import Loader from "../../common/Loader";
import { useForm } from "react-hook-form";

export default function NewUser() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    localStorage.removeItem("contact_number");
    localStorage.setItem("contact_number", JSON.stringify(data.contact_number));
    setIsLoading(true);
    await sendOTP(data).catch(console.error);
    setIsLoading(false);
    navigate("/verify-user");
  };

  return (
    <div>
      {isLoading && <Loader />}
      <div className="play-and-membership mt-3">
        <div className="container-fluid g-0">
          <div className="facilities-wrapper">
            <div className="facility-right two new-user-form">
              <div className="membership-form two">
                <div className="row">
                  <div className="col-lg-7">
                    <div className="title white two mt-5">
                      <h2>You Are Just One Step Away!</h2>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        {/* <div className="col-md-12">
                          {errors.name && (
                            <span className="text-danger">
                              {errors.name.message}
                            </span>
                          )}
                          <input
                            type="text"
                            placeholder="Enter name.."
                            {...register("name", {
                              required: "Enter name..",
                            })}
                          />
                        </div> */}
                        <div className="col-md-12">
                          <input
                            type="number"
                            className="mb-0 fw-bold digit-ls"
                            placeholder="Enter Your Mobile Number"
                            maxLength={10}
                            {...register("contact_number", { required: true, minLength: 10, maxLength: 10 })}
                          />
                          {errors.contact_number &&
                            errors.contact_number.type === "required" && (
                              <p className="text-warning small">Phone number is required.</p>
                            )}
                          {errors.contact_number &&
                            errors.contact_number.type === "minLength" && (
                              <p className="text-warning small">Phone number must be 10 digits.</p>
                            )}
                          {errors.contact_number &&
                            errors.contact_number.type === "maxLength" && (
                              <p className="text-warning small">Phone number must be 10 digits.</p>
                            )}
                        </div>
                        <ol
                          style={{ fontSize: "12px", color: "burlywood" }}
                          className="ms-3 mt-2"
                        >
                          <li>
                            Enter your 10-digit mobile number without any
                            country code.
                          </li>
                          <li>
                            Ensure that the entered contact number is valid, as
                            only valid numbers will receive the OTP.
                          </li>
                          <li>
                            In case of an invalid or incomplete number, the OTP
                            may not be received.
                          </li>
                        </ol>
                        <div className="col-12">
                          <div className="button--wrap button--wrap-two mt-3">
                            <button
                              className="eg-btn btn--primary golf-btn mx-auto"
                              type="submit"
                              style={{ zIndex: "auto" }}
                            >
                              Send OTP
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-lg-5">
                    <img
                      src="assets/img/new-user-form.png"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
