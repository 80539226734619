import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  createTimeSlotPricing,
  updateTimeSlotPricing,
  getCourts,
  getSingleCourt,
  getTimeSlotsByCourtId,
} from "../../../services/ApiServices";
import {
  timeSlotPricingAddedSuccess,
  timeSlotPricingUpdatedSuccess,
  toastTimeStamp,
} from "../../../constants/ResponseConstants";
import ToastPopup from "../ToastPopup";
import ReactDatePicker from "react-datepicker";
import Loader from "../../common/Loader";
import HelpIcon from "../../common/HelpIcon";

function ManageTimeSlotPricing(props) {
  const { id, CourtID, DayType, SlotName, SpecialDayDate } = props.mFor;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const [specialDayDate, setSpecialDayDate] = useState(new Date());
  const [courts, setCourts] = useState();
  const [court, setCourt] = useState();
  const [timeSlots, setTimeSlots] = useState();
  const [isStatusActive, setIsStatusActive] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isOffer, setIsOffer] = useState(true);

  // Toast State Start
  const [showToast, setShowToast] = useState(false);
  const [tMessage, setTMessage] = useState();
  const [tVariant, setTVariant] = useState();
  // Toast State End

  useEffect(() => {
    window.scrollTo(0, 0);
    isOffer && setValue("DayType", "S");
    if (props.mFor !== "Add") {
      reset(props.mFor);
      loadCourt();
    } else {
      loadAddData();
    }
  }, []);

  const onSubmit = async (data) => {
    const {
      CourtId,
      TimeSlotID,
      PriceVariation,
      DayType,
      OfferDescription,
      OfferImagePath,
      isActive,
      isOffer,
    } = data;

    const formData = new FormData();
    formData.append("Id", id !== (null || undefined) ? id : 1);
    formData.append("CourtID", CourtId);
    formData.append("TimeSlotID", TimeSlotID);
    formData.append("PriceVariation", PriceVariation);
    formData.append("DayType", DayType);
    formData.append("isOffer", isOffer);

    if (isOffer) {
      formData.append("specialDayDate", getFormattedDate());
      formData.append("OfferDescription", OfferDescription);
      typeof OfferImagePath !== "string" &&
        formData.append(
          "OfferImagePath",
          OfferImagePath[0],
          OfferImagePath[0].name
        );
    } else {
      formData.append("specialDayDate", null);
      formData.append("OfferDescription", null);
      typeof OfferImagePath !== "string" &&
        formData.append("OfferImagePath", null);
    }

    formData.append("isActive", isActive ? 1 : 0);
    props.mFor !== "Add" && formData.append("_method", "PUT");
    props.handleClose();
    setShowToast(true);

    try {
      const response = await (props.mFor === "Add"
        ? createTimeSlotPricing(formData)
        : updateTimeSlotPricing(formData, id));
      window.location.reload();
      // Toast Start
      setTMessage(response.message);
      setTVariant(
        response.message === timeSlotPricingUpdatedSuccess ||
          response.message === timeSlotPricingAddedSuccess
          ? "success"
          : "danger"
      );
      // Toast End
    } catch (error) {
      setTMessage(error);
      setTVariant("danger");
    }
    setTimeout(() => setShowToast(false), toastTimeStamp);
  };

  const loadAddData = async () => {
    setIsLoading(true);
    try {
      const response = await getCourts();
      setCourts(response.data);
      await getTSByCourtID(CourtID);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadCourt = async () => {
    setIsLoading(true);
    const response = await getSingleCourt(CourtID);
    setCourt(response.data);
    setIsLoading(false);
  };

  const getFormattedDate = () => {
    const day = ("0" + specialDayDate.getDate()).slice(-2); // Get day of the month
    const month = ("0" + (specialDayDate.getMonth() + 1)).slice(-2); // Get month (adding 1 since January is 0)
    const year = specialDayDate.getFullYear(); // Get year
    return `${year}-${month}-${day}`; // Format date as 'YYYY-MM-DD'
  };

  const getTSByCourtID = async (id) => {
    setIsLoading(true);
    setTimeSlots([]);
    try {
      const { data: timeSlotsData } = await getTimeSlotsByCourtId(id);
      setTimeSlots(timeSlotsData);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onClickOffer = async (offer) => {
    setIsOffer(offer);
    offer && setValue("DayType", "S");
  };

  return (
    <>
      {isLoading && <Loader />}
      {showToast && (
        <ToastPopup isShow={showToast} variant={tVariant} message={tMessage} />
      )}
      <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        dialogClassName="modal-90w"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.mFor === "Add" ? "Add" : "Edit"} Time Slot Pricing
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
            <div className="row mb-3">
              <div className="col-12">
                {props.mFor === "Add" ? (
                  <select
                    {...register("CourtId", { required: true })}
                    className="form-select mb-0"
                    onChange={(e) => getTSByCourtID(e.target.value)}
                  >
                    <option value="">Select Turf</option>
                    {courts &&
                      courts.map((item, i) => (
                        <option key={i} value={item.id}>
                          {item.court_name}
                        </option>
                      ))}
                  </select>
                ) : (
                  <p>
                    Turf Name :{" "}
                    <span className="fw-bold">{court?.court_name}</span>
                  </p>
                )}
              </div>
              {errors.CourtId && (
                <span className="text-danger small">Turf is required.</span>
              )}
            </div>
            <div className="row mb-3">
              <div className="col-12">
                {props.mFor === "Add" ? (
                  <select
                    {...register("TimeSlotID", { required: true })}
                    className="form-select mb-0"
                  >
                    <option value="">Select Time Slot</option>
                    {timeSlots &&
                      timeSlots.map((item, i) => (
                        <option key={i} value={item.ID}>
                          {item.SlotName}
                        </option>
                      ))}
                  </select>
                ) : (
                  <p>
                    Slot Name : <span className="fw-bold">{SlotName}</span>
                  </p>
                )}
              </div>
              {errors.TimeSlotID && (
                <span className="text-danger small">
                  Time slot is required.
                </span>
              )}
            </div>
            <div className="row">
              <div className="col-6 ms-auto">
                {props.mFor === "Add" ? (
                  <select
                    {...register("DayType", { required: "Select Day Type" })}
                    className="form-select mb-0"
                    disabled={props.mFor !== "Add" ? true : false}
                    onChange={(e) => isOffer && setValue("DayType", "S")}
                  >
                    <option value="">Select Day Type</option>
                    <option value="S">Special Day</option>
                    <option value="W">Weekend Day</option>
                    <option value="N">Normal Day</option>
                  </select>
                ) : (
                  <p>
                    Day Type :{" "}
                    <span className="fw-bold">
                      {DayType === "S"
                        ? "Special Day"
                        : DayType === "W"
                        ? "Weekend Day"
                        : "Normal Day"}
                    </span>
                  </p>
                )}
                {errors.DayType && (
                  <span className="text-danger small">
                    Day type is required.
                  </span>
                )}
                <p className="note">
                  Change offer to{" "}
                  <q>
                    <strong>No</strong>
                  </q>{" "}
                  to switch day type.
                </p>
                {/* <HelpIcon
                  helpText={`If you want to change day type then you should change offer to <q><strong>No</strong></q>.`}
                /> */}
              </div>
              <div className="col-6">
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Price Variation eg. -10 or 10"
                    {...register("PriceVariation", {
                      required: {
                        value: true,
                        message: "Price Variation is required.",
                      },
                      max: { value: 100, message: "Maximum 100%" },
                      min: { value: -100, message: "Minimum -100%" },
                    })}
                  />
                  <span className="input-group-text">%</span>
                </div>
                {errors.PriceVariation && (
                  <span className="text-danger small">
                    {errors.PriceVariation.message}
                  </span>
                )}
                <p className="note">
                  Please input positive values to increase pricing or negative
                  values to decrease pricing.
                </p>
                {/* <HelpIcon
                  helpText={`Please input positive values to increase pricing or negative values to decrease pricing.`}
                /> */}
              </div>
            </div>
            <div className="row mb-3">
              <label
                className="col-sm-4 col-form-label"
                style={{ fontSize: 14 }}
              >
                is this offer? <span className="text-danger">*</span>:
              </label>
              <div className="col-sm-8 form-check form-switch">
                <label className="form-label mb-0" style={{ fontSize: 14 }}>
                  {isOffer ? "Yes" : "No"}
                </label>
                <input
                  className="form-check-input bg-success border-success"
                  type="checkbox"
                  role="switch"
                  defaultChecked
                  {...register("isOffer")}
                  onChange={() => onClickOffer(!isOffer)}
                />
              </div>
            </div>
            {isOffer && (
              <>
                <div className="row mb-3">
                  {props.mFor === "Add" ? (
                    <>
                      <label
                        className="col-sm-4 col-form-label"
                        style={{ fontSize: 14 }}
                      >
                        Select Date <span className="text-danger">*</span>:
                      </label>
                      <div className="col-sm-8 ps-0">
                        <ReactDatePicker
                          selected={specialDayDate}
                          minDate={new Date()}
                          dateFormat={"yyyy-MM-dd"}
                          className="form-control mb-0 z-3"
                          onChange={(date) => setSpecialDayDate(date)}
                          disabled={props.mFor !== "Add" ? true : false}
                        />
                        {errors.SpecialDayDate && (
                          <p className="text-warning small">* Select Date</p>
                        )}
                      </div>
                    </>
                  ) : (
                    <p>
                      Offer Date :{" "}
                      <span className="fw-bold">{SpecialDayDate}</span>
                    </p>
                  )}
                </div>
                <div className="row mb-3">
                  <div className="col-12">
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Offer Description"
                      {...register("OfferDescription")}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label
                    className="col-sm-4 col-form-label"
                    style={{ fontSize: 14 }}
                  >
                    Offer Image
                  </label>
                  <div className="col-sm-8 ps-0">
                    <input
                      type="file"
                      className="form-control col-sm-8"
                      accept="image/*"
                      {...register("OfferImagePath")}
                    />
                  </div>
                </div>
              </>
            )}
            <div className="row mb-3">
              <label
                className="col-sm-4 col-form-label"
                style={{ fontSize: 14 }}
              >
                Status <span className="text-danger">*</span>:
              </label>
              <div className="col-sm-8 form-check form-switch">
                <label className="form-label mb-0" style={{ fontSize: 14 }}>
                  {isStatusActive ? "Active" : "InActive"}
                </label>
                <input
                  className="form-check-input bg-success border-success"
                  type="checkbox"
                  role="switch"
                  {...register("isActive")}
                  defaultChecked
                  onChange={() => setIsStatusActive(!isStatusActive)}
                />
              </div>
            </div>

            <Modal.Footer>
              <div
                className="button--wrap button--wrap-two mt-0"
                onClick={props.handleClose}
              >
                <button className="eg-btn btn--primary golf-btn mx-auto z-0">
                  <Link className="text-light" to="">
                    Close
                  </Link>
                </button>
              </div>
              <div className="button--wrap button--wrap-two mt-0">
                <button
                  className="eg-btn btn--primary golf-btn mx-auto z-0"
                  type="submit"
                >
                  <span className="text-light">
                    {props.mFor === "Add" ? "Add" : "Save"}
                  </span>
                </button>
              </div>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ManageTimeSlotPricing;
