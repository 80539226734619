import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAdmin, getServices } from "../../../services/ApiServices";
import { ImagePath } from "../../../services/ShortFunctions";

export default function Footer() {
  useEffect(() => {
    getAdminData();
    getAllServices();
  }, []);

  const [orgData, setOrgData] = useState([]);
  const [services, setServices] = useState([]);

  const getAdminData = async () => {
    const response = await getAdmin(1).catch(console.error);
    setOrgData(response.data);
  };

  const getAllServices = async () => {
    const response = await getServices().catch(console.error);
    setServices(response.data);
  };

  return (
    <>
      <footer className="pt-4">
        <div className="container">
          <div className="footer-top">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-xl-8">
                <div className="footer-logo mb-2">
                  {/* <h2>BnB - BAT N BALL TURF</h2> */}
                  <a href="index.html">
                    <img
                      src={ImagePath(orgData.org_logo)}
                      alt="MyTurf"
                      className="w-50"
                    />
                  </a>
                  <p>
                    Experience cricketing bliss with our exceptional turf
                    services, featuring pristine pitches, modern amenities, and
                    expert support an unmatched game!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-middle py-2">
            <div className="row gy-5">
              <div className="col-md-6 col-lg-2">
                <div className="footer-widget two">
                  {orgData && (
                    <div className="social-media">
                      <h4>Find Here:</h4>
                      <ul className="social-icons">
                        <li>
                          <a href={orgData.whatsapp}>
                            <i className="fab fa-whatsapp"></i>
                          </a>
                        </li>
                        <li>
                          <a href={orgData.instagram}>
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a href={orgData.twitter}>
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href={orgData.facebook}>
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6 col-lg-2">
                <div className="footer-widget two">
                  <h4>Services</h4>
                  <ul className="footer-menu">
                    {services &&
                      services.map((item, i) => (
                        <li key={i}>
                          <Link to={"/services"}>{item.name}</Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="footer-widget two">
                  <h4>Hours of Open</h4>
                  <ul className="office-time">
                    <li>Sunday – Saturday :</li>
                    <li>6:00 AM – 10:00 PM</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 col-lg-5">
                <div className="footer-widget two">
                  <h4>Contacts</h4>
                  <div className="number">
                    <div className="num-icon">
                      <i>
                        <img src="/assets/img/icons/phone.svg" alt="MyTurf" />
                      </i>
                    </div>
                    {orgData && (
                      <div className="phone">
                        <Link to={`tel:${orgData.contact_number_one}`}>
                          +91 {orgData.contact_number_one}
                        </Link>
                        <Link to={`tel:${orgData.contact_number_two}`}>
                          +91 {orgData.contact_number_two}
                        </Link>
                      </div>
                    )}
                  </div>
                  <div className="office-mail">
                    <div className="mail-icon">
                      <i className="far fa-envelope"></i>
                    </div>
                    <div className="email">
                      <Link to={`mailto:${orgData.email_id}`}>
                        <span className="__cf_email__">{orgData.email_id}</span>
                      </Link>
                    </div>
                  </div>
                  <div className="address">
                    <div className="address-icon">
                      <i className="fas fa-map-marker-alt"></i>
                    </div>
                    <p>
                      {orgData.address_line}
                      {orgData.address_line && ', '}
                      {orgData.city}{orgData.city && ', '}
                      {orgData.district}{orgData.district && ', '}
                      {orgData.state}{' - '}{orgData.pin_code}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row align-items-center">
              <div className="col-12 col-md-4 col-lg-4 col-xl-5">
                <div className="copy-txt two">
                  <span>
                    Copyright © 2023.{" "}
                    <b className="text-uppercase">{orgData.org_name}</b> |
                    Designed By{" "}
                    <Link to={"https://www.atjoin.in"}>
                      <span style={{ fontWeight: "bold", color: "#D3682A" }}>
                        ATJOIN PVT. LTD.
                      </span>
                    </Link>
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-8 col-xl-7">
                <ul className="footer-bottom-menu two">
                  <li>
                    <Link to={"/privacy-policy"}>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to={"/terms-of-use"}>Terms of Use</Link>
                  </li>
                  <li>
                    <Link to={"/terms-of-service"}>Terms of Service</Link>
                  </li>
                  <li>
                    <Link to={"/support-policy"}>Support Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
