import React, { useEffect, useState } from "react";
import { getBookings, getCourts } from "../../../services/ApiServices";
import { useForm } from "react-hook-form";
import { BOOKING_DETAILS, USER_ID} from "../../../constants/DataConstants";
import { Modal } from "react-bootstrap";
import { formattedTime, getTodaysDate } from "../../Helper";

export default function MyBookings() {
  const [bookings, setBookings] = useState([]);
  const [courts, setCourts] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [modalItem, setModalItem] = useState({});

  const { register, handleSubmit } = useForm();
  const {
    court_name,
    description,
    start_time,
    End_Time,
    Duration,
    net_total,
    other_charges,
    payment_mode,
    payment_status,
    trans_id,
    merchant_trans_id,
    booking_status,
  } = modalItem;

  useEffect(() => {
    window.scrollTo(0, 0);
    onSubmit({ CourtID: 0, DateFrom: getTodaysDate(), DateTo: getTodaysDate(), UserID: USER_ID });
    getAllCourts();
  }, []);

  const onSubmit = async (data) => {
    await fetchBookings(data);
  };

  const fetchBookings = async ({ CourtID, DateFrom, DateTo, UserID }) => {
    const params = {
      CourtID: CourtID || 0,
      DateFrom: DateFrom || getTodaysDate(),
      DateTo: DateTo || getTodaysDate(),
      UserID: USER_ID,
    };

    try {
      const { data } = await getBookings(params).catch(console.error);
      setBookings(data);
    } catch (error) {
      console.error(error);
    }
  };

  const getAllCourts = async () => {
    try {
      const response = await getCourts().catch(console.error);
      setCourts(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const DetailsModal = () => {
    return (
      <Modal
        backdrop="static"
        show={showDetails}
        onHide={() => setShowDetails(false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Booking Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-one rounded-2 text-white">
          <div className="d-flex my-3">
            <i className="fa-solid fa-caret-right me-2 mt-1"></i>
            <span className="fw-bold">Turf Name: &nbsp;</span> {court_name}
          </div>
          <div className="d-flex mb-3">
            <i className="fa-solid fa-caret-right me-2 mt-1"></i>
            <span className="fw-bold">Description: &nbsp;</span> {description}
          </div>
          <div className="d-flex mb-3">
            <i className="fa-solid fa-caret-right me-2 mt-1"></i>
            <span className="fw-bold">Date: &nbsp;</span>{" "}
            {BOOKING_DETAILS.booking_date}
          </div>
          <div className="d-flex mb-3">
            <i className="fa-solid fa-caret-right me-2 mt-1"></i>
            <span className="fw-bold">Time: &nbsp;</span> {start_time} -{" "}
            {End_Time}
          </div>
          <div className="d-flex mb-3">
            <i className="fa-solid fa-caret-right me-2 mt-1"></i>
            <span className="fw-bold">Duration: &nbsp;</span> {Duration}
          </div>
          <div className="d-flex mb-3">
            <i className="fa-solid fa-caret-right me-2 mt-1"></i>
            <span className="fw-bold">Booking Status: &nbsp;</span>{" "}
            {/* {booking_status} */}Success
          </div>
          <h4>Payment Details:</h4>
          <table className="table border border-2 border-warning">
            <tr>
              <td className="text-center">
                <div className="d-flex align-items-center">
                  Paid Amount: &nbsp;
                  <span className="h4 mb-0">5000</span>
                </div>
              </td>
              <td className="text-center">
                <div className="d-flex align-items-center">
                  Other Charges: &nbsp;
                  <span className="h4 mb-0">0.00</span>
                </div>
              </td>
            </tr>
          </table>
          <div className="d-flex mb-3">
            <i className="fa-solid fa-caret-right me-2 mt-1"></i>
            <span className="fw-bold">Payment Mode: &nbsp;</span> Online
          </div>
          <div className="d-flex mb-3">
            <i className="fa-solid fa-caret-right me-2 mt-1"></i>
            <span className="fw-bold">Payment Status: &nbsp;</span> Success
          </div>
          <div className="d-flex mb-3">
            <i className="fa-solid fa-caret-right me-2 mt-1"></i>
            <span className="fw-bold">Transaction ID.: &nbsp;</span> 1234567890
          </div>
          <div className="d-flex mb-3">
            <i className="fa-solid fa-caret-right me-2 mt-1"></i>
            <span className="fw-bold">Merchant Transaction ID.: &nbsp;</span>
            1234567890
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mb-3">
          <div className="row g-1 align-items-center">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <select
                className="form-select"
                {...register("CourtID", { required: false })}
              >
                <option value={0}>Select Turf</option>
                {courts.map((item, i) => (
                  <option key={i} value={item.id}>
                    {item.court_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <input
                type="date"
                className="form-control"
                {...register("DateFrom", { required: false })}
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <input
                type="date"
                className="form-control"
                {...register("DateTo", { required: false })}
              />
            </div>
            <div className="button--wrap button--wrap-two col-lg-2 col-md-6 col-sm-12 mt-0">
              <button
                type="submit"
                className="eg-btn btn--primary golf-btn mx-auto py-2"
              >
                <i className="fas fa-arrow-right ms-0 me-2"></i>Apply
              </button>
            </div>
          </div>
        </div>
      </form>
      {bookings?.length > 0 ? (
        <>
          {bookings.map((item, i) => (
            <div className="single-information" key={i}>
              <div className="row w-100">
                <div className="col-lg-8">
                  <div className="info-cnt b-info mb-lg-0 mb-2">
                    <h5>Turf Name: {item.court_name}</h5>
                    <p>
                      <span className="fw-bold text-one">Date:</span>{" "}
                      {item.booking_date}
                      <span className="fw-bold ms-4 text-one"> Time:</span>{" "}
                      {formattedTime(item.start_time)} -{" "}
                      {formattedTime(item.End_Time)}
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="button--wrap button--wrap-two mt-1">
                    <button
                      className="eg-btn btn--primary golf-btn mx-auto"
                      type="button"
                      onClick={() => {
                        setShowDetails(true);
                        setModalItem(item);
                        console.log("🚀 ~ MyBookings ~ item:", item);
                      }}
                    >
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className="text-center">
          <img src="/assets/img/r-not-found.png" alt="Not-Found" />
        </div>
      )}
      {showDetails && <DetailsModal />}
    </>
  );
}
