import React, { useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import { getAdmin } from "../../../services/ApiServices";
import { ImagePath } from "../../../services/ShortFunctions";
import { ADMIN_DATA, USER_ID } from "../../../constants/DataConstants";

export default function Header() {
  let navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    getAdminData();
  }, []);

  const [isTogglerOpen, setTogglerOpen] = useState(false);
  const [orgData, setOrgData] = useState([]);
  let adminAuth = ADMIN_DATA !== null;
  let userAuth = USER_ID !== null;

  const handleTogglerClick = () => {
    setTogglerOpen(!isTogglerOpen);
  };

  const handleNavLinkClick = () => {
    setTogglerOpen(false); // Close the toggler when a nav link is clicked
  };

  const getAdminData = async () => {
    const response = await getAdmin(1).catch(console.error);
    setOrgData(response.data);
  };

  return (
    <>
      <header className="header-two position_top shadow-sm py-0">
        <nav className="navbar navbar-expand-lg navbar-light bg-white py-1">
          <div className="container">
            <Link className="navbar-brand" to={"/"}>
              <img
                src={
                  orgData.org_logo
                    ? ImagePath(orgData.org_logo)
                    : "assets/img/logo.png"
                }
                className="img-fluid"
                alt="logo"
                style={{ maxWidth: 128 }}
              />
            </Link>
            <button
              className={`navbar-toggler ${isTogglerOpen ? "" : "collapsed"}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded={isTogglerOpen ? "true" : "false"}
              aria-label="Toggle navigation"
              onClick={handleTogglerClick}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`collapse navbar-collapse ${
                isTogglerOpen ? "show" : ""
              }`}
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto">
                <li className="nav-item fw-bold">
                  <NavLink
                    className="nav-link fs-6"
                    to="/"
                    onClick={handleNavLinkClick}
                  >
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link fs-6"
                    to="/about-us"
                    onClick={handleNavLinkClick}
                  >
                    About Us
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link fs-6"
                    to="/services"
                    onClick={handleNavLinkClick}
                  >
                    Services
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link fs-6"
                    to="/courts"
                    onClick={handleNavLinkClick}
                  >
                    Turfs
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link fs-6"
                    to="/contact-us"
                    onClick={handleNavLinkClick}
                  >
                    Contact Us
                  </NavLink>
                </li>
              </ul>
              <div className="d-flex" style={{ zIndex: "auto" }}>
                {/* <div className="btn-block mb-sm-1 mb-md-0"> */}
                <div
                  className="membership-btn two me-2"
                  onClick={() => {localStorage.removeItem("courtId"); window.location.reload();}} >
                  <Link to={adminAuth ? "/admin/book-now" : "/booking-form"}>
                    <i className="fa-solid fa-pencil"></i> Book Now
                  </Link>
                </div>
                {/* </div> */}
                {!adminAuth && userAuth && (
                  <div className="membership-btn two">
                    <Link to="/user/booking">
                      <i className="fa-solid fa-user"></i> Account
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}
