export const toastTimeStamp = 5000;

// ADMIN CONSTANTS START
// Court Response Start
export const courtAddedSuccess = "Court Added Successfully.";
export const courtUpdatedSuccess = "Court Updated Successfully.";
export const courtDeleteSuccess = "Court is Deleted Successfully.";
export const courtStatusUpdateSuccess = "Court Status is Updated Successfully.";
// Court Response End

// CourtSport Response Start
export const CourtSportAddedSuccess = "Court Sport Added Successfully.";
export const CourtSportUpdatedSuccess = "Court Sport Updated Successfully.";
export const CourtSportDeleteSuccess = "Court Sport Deleted Successfully.'";
// CourtSport Response End

//Manage Service Start
export const serviceAddedSuccess = "Service Added Successfully.";
export const serviceUpdatedSuccess = "Service Updated Successfully.";
export const serviceDeleteSuccess = "Service Deleted Successfully.";
//Manage Service End

//Manage Sport Start
export const sportAddedSuccess = "Sport Added Successfully.";
export const sportUpdatedSuccess = "Sport Updated Successfully.";
export const sportDeleteSuccess = "Sport is Deleted Successfully.";
//Manage Sport End

//Manage User Status Start
export const userStatusUpdateSuccess = "User Status Updated Successfully.";
//Manage User Status End
//Manage Sport End

//Manage User Status Start
export const bookingSuccess = "Booking Done Successfully.";
//Manage User Status End

//Manage Admin Status Start
export const adminProfileUpdateSuccess = "Profile Updated Successfully.";
export const adminLogoUpdateSuccess = "Logo Updated Successfully.";
export const adminPasswordUpdateSuccess = "Password Upadated Successfully.";
export const adminLoginSuccess = "Login Successfully.";
export const adminLoginFailed = "Admin Not Exist.";
//Manage Admin Status End
// ADMIN CONSTANTS START

// USER CONSTANTS START
export const invalidContactOrOTP = "Invalid Contact Number or OTP.";
// USER CONSTANTS END

// Contact Us Message Send Start
export const contactUsMessageSendSuccessfully =
  "Your message has been sent successfully.";
// Contact Us Message Send End

// Booking Form Message Start
// If Select Past Time Start
export const CourtNotAvailable = "Court not available within Specified!!!";
// If Select Past Time End
// Booking Form Message End

// TimeSlot Management Message Start
export const timeSlotUpdatedSuccess = "TimeSlot updated successfully.";
export const timeSlotDeleteSuccess = "TimeSlot deleted successfully.";
export const timeSlotAddedSuccess = "TimeSlot added successfully.";
// TimeSlot Management Message End

// TimeSlot Pricing Management Message Start
export const timeSlotPricingUpdatedSuccess =
  "TimeSlot Pricing updated successfully.";
export const timeSlotPricingDeleteSuccess =
  "TimeSlot Pricing deleted successfully.";
export const timeSlotPricingAddedSuccess =
  "TimeSlot Pricing added successfully.";
// TimeSlot Pricing Management Message End
