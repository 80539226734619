import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAdmin } from "../../../services/ApiServices";

export default function TermOfUse() {
  useEffect(() => {
    window.scrollTo(0, 0);
    getAdminData();
  }, []);

  const [orgData, setOrgData] = useState([]);

  const getAdminData = async () => {
    const response = await getAdmin(1).catch(console.error);
    setOrgData(response.data);
  };

  return (
    <div className="container my-5">
      <h4>Terms of Use for AT Turf</h4>
      <p>
        Please read these Terms of Use ("Terms") carefully before using the AT
        Turf website ("website"). By accessing or using the website, you agree
        to be bound by these Terms, as well as any additional terms and
        conditions provided within specific areas of the website. If you do not
        agree with any provisions of these Terms, please refrain from using the
        website.
      </p>
      <ol type="1">
        <li className="fw-bold h5">Intellectual Property:</li>
        <p>
          All content on the website, including but not limited to text,
          graphics, logos, images, and software, is the property of AT Turf or
          its content suppliers and is protected by intellectual property laws.
          You may not reproduce, distribute, modify, or transmit any portion of
          the website's content without prior written consent from AT Turf.
        </p>
        <li className="fw-bold h5 mt-4">Use of the Website:</li>
        <ul>
          <li>
            You may use the website solely for your personal, non-commercial use
            in accordance with these Terms.
          </li>
          <li>
            You must not use the website in any way that violates applicable
            laws or regulations.
          </li>
          <li>
            You are solely responsible for any content you post or transmit on
            the website, and you must not engage in any activities that may harm
            the website or its users.
          </li>
        </ul>
        <li className="fw-bold h5 mt-4">User Accounts:</li>
        <ul>
          <li>
            In order to access certain features of the website, you may need to
            create a user account. You are responsible for maintaining the
            confidentiality of your account information and for any actions
            taken under your account.
          </li>
          <li>
            You must provide accurate and complete information when creating an
            account, and you must promptly update any changes to your account
            information.
          </li>
          <li>
            AT Turf reserves the right to suspend or terminate your account if
            you breach these Terms or for any other reason determined by AT Turf
            in its sole discretion.
          </li>
        </ul>
        <li className="fw-bold h5 mt-4">Third-Party Links:</li>
        <p>
          The website may contain links to third-party websites that are not
          owned or controlled by AT Turf. AT Turf has no control over the
          content, policies, or actions of these third-party websites and does
          not endorse or assume any responsibility for them. You access these
          third-party websites at your own risk, and you should review their
          terms and privacy policies.
        </p>
        <li className="fw-bold h5 mt-4">Disclaimer of Warranty:</li>
        <ul>
          <li>
            The website and its content are provided on an "as is" and "as
            available" basis. AT Turf makes no warranties, expressed or implied,
            regarding the website and its content, including but not limited to
            accuracy, reliability, fitness for a particular purpose, or
            non-infringement.
          </li>
          <li>
            AT Turf does not warrant that the website will be uninterrupted,
            error-free, or free from viruses or other harmful components. You
            are solely responsible for implementing appropriate measures to
            protect your device and data.
          </li>
        </ul>
        <li className="fw-bold h5 mt-4">Limitation of Liability:</li>
        <p>
          To the maximum extent permitted by law, AT Turf and its affiliates,
          officers, directors, employees, agents, and suppliers shall not be
          liable for any indirect, incidental, special, consequential, or
          punitive damages, including but not limited to loss of profits,
          revenue, data, or use arising out of or in connection with the use or
          inability to use the website.
        </p>
        <li className="fw-bold h5 mt-4">Governing Law and Jurisdiction:</li>
        <p>
          These Terms shall be governed by and construed in accordance with the
          laws of [Jurisdiction]. Any disputes arising out of or relating to
          these Terms or the use of the website shall be resolved exclusively in
          the courts located in [Jurisdiction].
        </p>
        <li className="fw-bold h5 mt-4">Modifications:</li>
        <p>
          AT Turf reserves the right to modify or discontinue the website, its
          features, or these Terms at any time without prior notice. It is your
          responsibility to review these Terms periodically for any changes.
        </p>
        <li className="fw-bold h5 mt-4">Severability:</li>
        <p>
          If any provision of these Terms is held to be invalid or
          unenforceable, the remaining provisions shall continue in full force
          and effect.
        </p>
        <li className="fw-bold h5 mt-4">Contact Information:</li>
        <p>
          If you have any questions, concerns, or feedback regarding these
          Terms, please contact us at:
        </p>
        {orgData && (
          <>
            Email ID.:{" "}
            <Link
              to={`mailto:${orgData.email_id}`}
              className="text-dark fw-bold"
            >
              {orgData.email_id}
            </Link>
            <br />
            <div className="d-flex">
              Phone No.:
              <div className="ms-1">
                <Link
                  to={`tel:${orgData.contact_number_one}`}
                  className="text-dark fw-bold"
                >
                  +91 {orgData.contact_number_one}
                </Link>
                <br />
                <Link
                  to={`tel:${orgData.contact_number_two}`}
                  className="text-dark fw-bold"
                >
                  +91 {orgData.contact_number_two}
                </Link>
              </div>
            </div>
            <div className="d-flex">
              Address:
              <div className="text-dark fw-bold ms-1">
                {" "}
                {orgData.address_line}
                {", "}
                {orgData.city}
                {", "}
                {orgData.city}
                {", "}
                {orgData.district}
                {", "}
                {orgData.state}
                {" - "}
                {orgData.pin_code}
              </div>
            </div>
          </>
        )}
        <p>
          By using the AT Turf website, you signify your acceptance of these
          Terms of Use.
        </p>
      </ol>
    </div>
  );
}
