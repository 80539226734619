import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getSports,
  getPrice,
  getSportWiseCourts,
  getSingleCourt,
  geCourtWiseSports,
  getBookings,
  getCourts
} from "../../../services/ApiServices";
import Loader from "../../common/Loader";
import { useForm } from "react-hook-form";
import { COURT_ID, USER_ID } from "../../../constants/DataConstants";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toastTimeStamp } from "../../../constants/ResponseConstants";
import ToastPopup from "../../modals/ToastPopup";
import { formattedTime, getTodaysDate } from "../../Helper";

export default function BookingForm() {
  useEffect(() => {
    window.scrollTo(0, 0);
    !COURT_ID && loadSports();
    // COURT_ID && setValue("court_id", COURT_ID);
    COURT_ID && loadCourts();
    COURT_ID && fetchCourtSports();
    fetchBookings();
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [sports, setSports] = useState(false);
  // const [courts, setCourts] = useState();
  const [duration, setDuration] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [getCourt, setGetCourt] = useState([]);

  // Toast State Start
  const [showToast, setShowToast] = useState(false);
  const [tMessage, setTMessage] = useState();
  const [tVariant, setTVariant] = useState();
  // Toast State End

  const [bookings, setBookings] = useState([]);
  const [courts, setCourts] = useState([]);
  const { register: register1, handleSubmit: handleSubmit1 } = useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllCourts();
    fetchBookings({ CourtID: 0, DateFrom: getTodaysDate(), DateTo: getTodaysDate(), UserID: 0 });
  }, []);

  const onSubmit1 = async (data) => {
    await fetchBookings(data);
  };

  const fetchBookings = async ({ CourtID, DateFrom, DateTo, UserID }) => {
    const params = {
      CourtID: CourtID || 0,
      DateFrom: DateFrom || getTodaysDate(),
      DateTo: DateTo || getTodaysDate(),
      UserID: UserID || 0,
    };

    try {
      const { data } = await getBookings(params).catch(console.error);
      setBookings(data);
    } catch (error) {
      console.error(error);
    }
  };

  const getAllCourts = async () => {
    const response = await getCourts().catch(console.error);
    setCourts(response.data);
  };

  const loadSports = async () => {
    setIsLoading(true);
    const response = await getSports().catch(console.error);
    setSports(response.data);
    setIsLoading(false);
  };

  const loadCourts = async () => {
    try {
      const response = await getSingleCourt(COURT_ID).catch(console.error);
      setGetCourt(response.data);
      setValue("court_id", COURT_ID);
    } catch (error) {
      console.error(error);
    }
  };

  // const manageDuration = (a) => setDuration(duration + (a === "D" && duration > 1 ? -1 : duration === 1 ? +0 : +1));

  const manageDuration = (a) =>
    setDuration(
      a === "I" ? duration + 1 : duration - 1 >= 1 ? duration - 1 : duration
    );

  const onSubmit = async (bData) => {
    let sTime = `${startTime.getHours().toString().padStart(2, "0")}:${startTime
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
    const data = {
      booking_date: startDate.toISOString().slice(0, 10),
      start_time: sTime,
      ...bData,
    };

    data.end_time = calculateEndTime(data);
    // getPrice code start
    const getPriceData = {
      BKStart_time: data.start_time + ":00",
      BKEnd_Time: data.end_time + ":00",
      CourtId: data.court_id,
      BookingDate: startDate.toISOString().slice(0, 10),
    };
    try {
      const response = await getPrice(getPriceData);
      let finalTotalPrice = 0,
        baseTotalPrice = 0;
      for (let index = 0; index < response.data.length; index++) {
        finalTotalPrice += Number(response.data[index]["FinalPrice"]);
        baseTotalPrice += Number(response.data[index]["BasePrice"]);
      }
      if (finalTotalPrice !== 0 && baseTotalPrice !== 0) {
        const newData = { baseTotalPrice, finalTotalPrice, ...data };
        localStorage.setItem("BookingDetails", JSON.stringify(newData));
        USER_ID === null ? navigate("/new-user") : navigate("/booking-summary");
      }
    } catch (error) {
      if (error.response) {
        // Toast Start
        setShowToast(true);
        setTMessage("<b>" + error.response.data.message.errorInfo[2] + "</b>.");
        setTVariant("danger");
        setTimeout(() => setShowToast(false), toastTimeStamp);
        // Toast End
      } else if (error.request) {
        console.error("Network Error: ", error.request);
      } else {
        console.error("Error: ", error.message);
      }
    }
    // getPrice code end
  };

  const calculateEndTime = ({ start_time }) => {
    const [hours, minutes] = start_time.split(":").map(Number);
    const totalMins = hours * 60 + minutes + duration * 60;
    const endHours = Math.floor(totalMins / 60) % 24;
    const endMinutes = totalMins % 60;
    return `${endHours.toString().padStart(2, "0")}:${endMinutes
      .toString()
      .padStart(2, "0")}`;
  };

  const fetchCourtsForSport = async (sportId) => {
    try {
      const { data: courts } = await getSportWiseCourts(sportId, "T");
      setGetCourt(courts);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCourtSports = async () => {
    try {
      const response = await geCourtWiseSports(COURT_ID, "C");
      setSports(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  // Select Future Time Start
  const isToday = (date) => date.toDateString() === new Date().toDateString();

  // Calculate the minimum time as the current time if today's date is selected
  const minTime = isToday(startDate) ? new Date() : null;

  // Calculate the maximum time as the end of the day if today's date is selected
  const maxTime = isToday(startDate)
    ? new Date(new Date().setHours(23, 59, 59, 999))
    : null;
  // Select Future Time End

  // Custom input component to disable manual input
  const CustomTimeInput = ({ value, onClick }) => (
    <input
      type="text"
      value={value}
      onClick={onClick}
      readOnly // Disable manual input
    />
  );

  return (
    <div>
      {isLoading && <Loader />}
      {showToast && (
        <ToastPopup isShow={showToast} variant={tVariant} message={tMessage} />
      )}
      <div className="play-and-membership">
        <div className="container-fluid g-0">
          <div className="facilities-wrapper">
            <div className="facility-left two" style={{ zIndex: "auto" }}>
              <div className="facility-img two">
                <img src="assets/img/play-and-membership.jpg" alt="Booking" />
              </div>
              <div className="feature two">
                <div className="row g-4">
                  <div className="col-md-12">
                    <div className="single-feature py-5">
                      {/* <i className="fas fa-cart-arrow-down fa-5x mb-4 text-light"></i>
                      <h2 className="text-light">Cart Is Empty</h2> */}
                      <div className="booking-details">
                        <h4 className="text-light">View Booked Slots</h4>
                        <form onSubmit={handleSubmit1(onSubmit1)}>
                          <div className="row">
                            <div className="row g-1 align-items-center">
                              <div className="col-lg-3 col-md-6 col-sm-12">
                                <select
                                  className="form-select"
                                  {...register1("CourtID", { required: false })}
                                >
                                  <option value={0}>Select Turf</option>
                                  {courts.map((item, i) => (
                                    <option key={i} value={item.id}>
                                      {item.court_name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <input
                                  type="date"
                                  className="form-control"
                                  {...register1("DateFrom")}
                                  min={getTodaysDate()} // Set min attribute to today's date
                                />
                              </div>
                              <div className="col-lg-4 col-md-6 col-sm-12">
                                <input
                                  type="date"
                                  className="form-control"
                                  {...register1("DateTo")}
                                  min={getTodaysDate()} // Set min attribute to today's date
                                />
                              </div>
                              <div className="button--wrap button--wrap-two col-lg-1 col-md-6 col-sm-12 mt-0">
                                <button
                                  type="submit"
                                  className="eg-btn btn--primary golf-btn p-2"
                                >
                                  <i className="fas fa-magnifying-glass ms-0"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="table-responsive">
                              <table className="table table-secondary-subtle table-striped mt-4">
                                <thead>
                                  <tr>
                                    <th>#</th>
                                    <th>Date</th>
                                    <th>Turf Name</th>
                                    <th>Start Time</th>
                                    <th>End Time</th>
                                    <th>Duration</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {bookings &&
                                    bookings.map((item, i) => (
                                      <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{item.booking_date}</td>
                                        <td>{item.court_name}</td>
                                        <td>
                                          {formattedTime(item.start_time)}
                                        </td>
                                        <td>{formattedTime(item.End_Time)}</td>
                                        <td>{item.Duration}</td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="facility-right two b-form">
              <div className="membership-form two">
                <div className="title white two mt-5">
                  <h2>Book Your Slot Now!</h2>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="row mb-3">
                      <label className="col-sm-3 col-form-label text-white">
                        Select Sport <span className="text-danger">*</span>:
                      </label>
                      <div className="col-sm-9">
                        <select
                          {...register("sport_id", {
                            required: "Sport is required.",
                          })}
                          className="form-select mb-0 fw-bold"
                          onChange={(e) =>
                            !COURT_ID && fetchCourtsForSport(e.target.value)
                          }
                        >
                          <option value="">Select Sport</option>
                          {sports.length > 0 &&
                            sports?.map((item, i) => (
                              <option
                                key={i}
                                value={COURT_ID ? item.SportId : item.id}
                                className="fw-bold"
                              >
                                {COURT_ID ? item.SportName : item.name}
                              </option>
                            ))}
                        </select>
                        {errors.sport_id && (
                          <small className="text-danger">
                            {errors.sport_id.message}
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label className="col-sm-3 col-form-label text-white">
                        Select Turf <span className="text-danger">*</span>:
                      </label>
                      {COURT_ID ? (
                        <div className="col-sm-9">
                          <select
                            {...register("court_id", {
                              required: "Turf is required.",
                            })}
                            className="form-select mb-0 fw-bold"
                          >
                            {getCourt && (
                              <option className="fw-bold" value={getCourt.id}>
                                {getCourt.court_name}
                              </option>
                            )}
                          </select>
                          {errors.court_id && (
                            <small className="text-danger">
                              {errors.court_id.message}
                            </small>
                          )}
                        </div>
                      ) : (
                        <div className="col-sm-9">
                          <select
                            {...register("court_id", {
                              required: "Turf is required.",
                            })}
                            className="form-select mb-0 fw-bold"
                            // disabled={COURT_ID}
                          >
                            <option value="">Select Turf</option>
                            {getCourt?.length > 0 &&
                              getCourt?.map((item, i) => (
                                <option
                                  key={i}
                                  className="fw-bold"
                                  value={item.CounrtId}
                                >
                                  {item.CourtName}
                                </option>
                              ))}
                          </select>
                          {errors.court_id && (
                            <small className="text-danger">
                              {errors.court_id.message}
                            </small>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="row mb-3">
                      <label className="col-sm-3 col-form-label text-white">
                        Select Date
                        <span className="text-danger fw-bold">*</span>:
                      </label>
                      <div className="col-sm-9">
                        <ReactDatePicker
                          selected={startDate}
                          minDate={new Date()}
                          dateFormat={"yyyy-MM-dd"}
                          className="mb-0 fw-bold"
                          onChange={(date) => {
                            setStartDate(date);
                            isToday(date) && setStartTime(null);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label className="col-sm-3 col-form-label text-white">
                        Start Time <span className="text-danger">*</span>:
                      </label>
                      <div className="col-sm-9">
                        <ReactDatePicker
                          className="mb-0 fw-bold"
                          selected={startTime}
                          onChange={(date) => setStartTime(date)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          minTime={minTime}
                          maxTime={maxTime}
                          dateFormat="hh:mm aa"
                          customInput={<CustomTimeInput />}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label className="col-sm-3 col-form-label text-white">
                        Duration <span className="text-danger">*</span>:
                      </label>
                      <div className="col-sm-9">
                        <i
                          onClick={() => manageDuration("D")}
                          className={`fas fa-minus mx-4 ${
                            duration > 1 ? "bg-dark" : ""
                          }`}
                        ></i>
                        <span>
                          {duration} {duration > 1 ? "Hrs." : "Hr."}
                        </span>
                        <i
                          onClick={() => manageDuration("I")}
                          className="fas fa-plus mx-4"
                        ></i>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="button--wrap button--wrap-two mt-0">
                        <button
                          className="eg-btn btn--primary golf-btn mx-auto fw-bold"
                          type="submit"
                          style={{ zIndex: "auto" }}
                        >
                          Proceed
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
