import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  checkPaymentStatus,
  createBookings,
  createPayment,
  getSingleUser,
} from "../../../services/ApiServices";
import {
  BOOKING_DETAILS,
  ONLINE_ORDER_DETAILS,
  PAYMENT_DETAILS,
  PAYMENT_RESPONSE,
  USER_ID,
  XVERIFY,
} from "../../../constants/DataConstants";
import Loader from "../../common/Loader";
import {
  bookingSuccess,
  toastTimeStamp,
} from "../../../constants/ResponseConstants";
import ToastPopup from "../../modals/ToastPopup";

export default function BookingSuccess() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [phonpePaymentStatus, setPhonpePaymentStatus] = useState();
  const [user, setUser] = useState();
  const [status, setStatus] = useState([]);

  // Toast State Start
  const [showToast, setShowToast] = useState(false);
  const [tMessage, setTMessage] = useState();
  const [tVariant, setTVariant] = useState();
  // Toast State End

  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log("ONLINE_ORDER_DETAILS", ONLINE_ORDER_DETAILS);
    // console.log("PAYMENT_DETAILS", PAYMENT_DETAILS);
    // console.log("BOOKING_DETAILS", BOOKING_DETAILS);
    console.log("🚀 ~ useEffect ~ PAYMENT_RESPONSE:", PAYMENT_RESPONSE);
    PAYMENT_DETAILS
      ? checkStatus(PAYMENT_DETAILS, ONLINE_ORDER_DETAILS)
      : navigate("/courts");
    saveOrder();
    getUser();
  }, []);

  const getUser = async () => {
    try {
      const { data } = await getSingleUser(USER_ID);
      setUser(data);
    } catch (error) {
      console.error(error);
    }
  };

  const {
    baseTotalPrice,
    booking_date,
    court_id,
    end_time,
    finalTotalPrice,
    sport_id,
    start_time,
  } = BOOKING_DETAILS;
  const {
    Amount,
    Code,
    Contact,
    Email,
    FName,
    LName,
    MerchantId,
    MerchantTransactionId,
    NetTotal,
    OrderStatus,
    OtherCharges,
    PaymentMode,
    Success,
    TransactionId,
    UserId,
  } = ONLINE_ORDER_DETAILS;
  // const { amount, callbackUrl, merchantId, merchantTransactionId, merchantUserId, mobileNumber, paymentInstrument, redirectMode, redirectUrl } = PAYMENT_DETAILS;

  const checkPaymentStatusAsync = async (merchantId, transactionId) => {
    return checkPaymentStatus(XVERIFY, merchantId, transactionId);
  };

  const checkStatus = async (data, data1) => {
    setIsLoading(true);
    const response = await checkPaymentStatusAsync(
      data.merchantId,
      data.merchantTransactionId
    );
    const { data: paymentData } = response;
    setStatus(paymentData);
    const status = paymentData?.state || "";

    setPhonpePaymentStatus(paymentData);
    switch (status) {
      case "COMPLETED":
        setIsCompleted(true);
        break;
      case "PENDING":
        setIsPending(true);
        break;
      default:
        setIsFailed(true);
    }

    if (isCompleted) {
      saveOrder(paymentData, data1);
    } else {
      paymentFailed(paymentData, data1);
    }

    setIsLoading(false);
  };

  const saveOrder = async () => {
    setIsLoading(true);
    const paymentData = {
      user_id: USER_ID,
      other_charges: OtherCharges,
      net_total: NetTotal / 100,
      payment_mode: "ONLINE",
      payment_status: PAYMENT_RESPONSE.success,
      booking_status: PAYMENT_RESPONSE.success,
      code: PAYMENT_RESPONSE.code,
      merchant_trans_id: PAYMENT_RESPONSE.data.merchantTransactionId,
      trans_id: status.transactionId,
      success: PAYMENT_RESPONSE.success,
    };
    try {
      const resPayment = await createPayment(paymentData);
      setPhonpePaymentStatus(resPayment.data);
    } catch (error) {
      console.error(error);
    }
    
    const bookingData = {
      UserId: USER_ID,
      CourtId: court_id,
      SportId: sport_id,
      BookingDate: booking_date,
      StartTime: start_time,
      EndTime: end_time,
      PaymentId: 0,
      StatusId: setIsCompleted ? 1 : 0,
      Description: "Booking",
      NameCustomer: FName + " " + LName,
      ContactCustomer: Contact,
    };
    try {
      const resBooking = await createBookings(bookingData);
      setIsLoading(false);
      if (resBooking.message === bookingSuccess) {
        setShowToast(true);
        setTMessage("<b>" + bookingSuccess + "</b>.");
        setTVariant("success");
        setTimeout(() => setShowToast(false), toastTimeStamp);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const paymentFailed = async (payDetails, orderDetails) => {
    orderDetails.Success = payDetails.success;
    orderDetails.Code = payDetails.code;
    orderDetails.MerchantId = payDetails.merchantId;
    orderDetails.MerchantTransactionId = payDetails.merchantTransactionId;
    orderDetails.TransactionId = payDetails.transactionId;
    orderDetails.Amount = payDetails.amount;
    const paymentData = {
      user_id: USER_ID,
      other_charges: orderDetails.OtherCharges,
      net_total: orderDetails.NetTotal / 100,
      payment_mode: orderDetails.PaymentMode,
      payment_status: orderDetails.success,
      booking_status: orderDetails.success,
      code: orderDetails.Code,
      merchant_trans_id: orderDetails.MerchantTransactionId,
      trans_id: orderDetails.TransactionId || "N/A",
      success: orderDetails.success,
    };
    await createPayment(paymentData).catch(console.error);
    localStorage.removeItem("ONLINE_ORDER_DETAILS");
    localStorage.removeItem("PAYMENT_DETAILS");
    setIsLoading(false);
    setShowToast(true);
    setTMessage("<b>Payment Failed.</b>");
    setTVariant("danger");
    setTimeout(() => setShowToast(false), toastTimeStamp);
  };

  return (
    <div>
      {isLoading ? <Loader /> : null}
      {showToast && (
        <ToastPopup isShow={showToast} variant={tVariant} message={tMessage} />
      )}

      {isCompleted && (
        <div className="facilities-wrapper container my-5 rounded-3 gap-5">
          <div className="facility-right two b-form card">
            <div className="card">
              <div className="card-header d-flex justify-content-center">
                <img
                  src="assets\img\icons\booking-success.png"
                  alt="Success"
                  className="w-25"
                />
              </div>
              <div className="card-body text-center">
                <div className="d-flex justify-content-center">
                  <i className="fa fa-check fs-4"></i>
                  <h3 className="ms-3 text-dark">Awesome..!</h3>
                </div>
                <p className="fw-bold text-one">
                  Your booking has been confirmed.
                </p>
                <div className="checkout-confirm">
                  {/* <img src="assets/images/okay.png" alt="Okay" /> */}
                  <h3>Payment Complete</h3>
                  {/* <h4>Success</h4> */}
                  <p>
                    Merchant Trans. ID:{" "}
                    <strong>
                      {ONLINE_ORDER_DETAILS.MerchantTransactionId}
                    </strong>{" "}
                  </p>
                  <p>
                    Transaction ID: <strong>{status.transactionId}</strong>
                  </p>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="btn-block mb-sm-1 mb-md-0 d-flex justify-content-center me-2">
                    <div className="membership-btn">
                      <Link to="/user/booking" style={{ zIndex: "auto" }}>
                        <i className="fa fa-eye"></i>&nbsp; View Booking
                      </Link>
                    </div>
                  </div>
                  <div className="btn-block mb-sm-1 mb-md-0 d-flex justify-content-center">
                    <div className="membership-btn">
                      <Link to="/" style={{ zIndex: "auto" }}>
                        <i className="fa fa-check"></i>&nbsp; Go To Home
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
