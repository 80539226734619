import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  getCourts,
  getSports,
  getSportsCourts,
  createSportCourt,
  updateSportCourt,
  getSingleSportCourt,
  deleteSportCourt,
} from "../../../services/ApiServices";
import {
  CourtSportAddedSuccess,
  CourtSportUpdatedSuccess,
  toastTimeStamp,
  CourtSportDeleteSuccess,
} from "../../../constants/ResponseConstants";
import ToastPopup from "../../modals/ToastPopup";
import Loader from "../../common/Loader";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { StringToHTML } from "../../../services/ShortFunctions";

function ManageCourtsSports() {
  useEffect(() => {
    window.scrollTo(0, 0);
    loadCourts();
    loadSports();
    getAllCourtsSports();
  }, []);

  const [courts, setCourts] = useState();
  const [sports, setSports] = useState();
  const [updateCourtSport, setUpdateCourtSport] = useState();
  const [courtSports, setCourtSports] = useState();
  const [isAddCourtSport, setIsAddCourtSport] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // Toast State Start
  const [showToast, setShowToast] = useState(false);
  const [tMessage, setTMessage] = useState();
  const [tVariant, setTVariant] = useState();
  // Toast State End

  const loadCourts = async () => {
    setIsLoading(true);
    try {
      const response = await getCourts().catch(console.error);
      setCourts(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadSports = async () => {
    setIsLoading(true);
    try {
      const response = await getSports().catch(console.error);
      setSports(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllCourtsSports = async () => {
    setIsLoading(true);
    try {
      const response = await getSportsCourts().catch(console.error);
      setCourtSports(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle onSubmit CourtSport Start
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const onSubmit = async (data) =>
    await (isAddCourtSport ? onAddCourtSport(data) : onUpdateCourtSport(data));
  // Handle onSubmit CourtSport Start

  // Handle Add CourtSport Start
  const onAddCourtSport = async (data) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("ID", 1);
    formData.append("CourtId", data.CourtId);
    formData.append("SportId", data.SportId);
    const response = await createSportCourt(formData).catch(console.error);
    setIsLoading(false);
    // Toast Start
    setShowToast(true);
    if (response?.message === CourtSportAddedSuccess) {
      setTMessage(CourtSportAddedSuccess);
      setTVariant("success");
      setIsAddCourtSport(true);
      getAllCourtsSports();
    } else {
      setTMessage(response.message);
      setTVariant("danger");
    }
    setTimeout(() => setShowToast(false), toastTimeStamp);
    // Toast End
  };
  // Handle Add CourtSport End

  // Handle Update CourtSport Start
  const onUpdateCourtSport = async (data) => {
    const formData = new FormData();
    formData.append("CounrtId", data.CourtId);
    formData.append("SportId", data.SportId);
    formData.append("_method", "PUT");
    const response = await updateSportCourt(
      formData,
      updateCourtSport.Id
    ).catch(console.error);
    getAllCourtsSports();
    // Toast Start
    setShowToast(true);
    if (response.message === CourtSportUpdatedSuccess) {
      setTMessage(response.message);
      setTVariant("success");
      setIsAddCourtSport(true);
    } else {
      setTMessage(response.message);
      setTVariant("danger");
    }
    setTimeout(() => setShowToast(false), toastTimeStamp);
    // Toast End
  };
  // Handle Update CourtSport End

  // Handle Edit Start
  const handleEdit = async (sc) => {
    setIsAddCourtSport(false);
    setUpdateCourtSport(sc);
    setValue("SportId", sc.SportId);
    setValue("CourtId", sc.CounrtId);
  };
  // Handle Edit End

  //Manage Turf Enable/Disable Status Start
  const handleDelete = async (id) => {
    confirmAlert({
      title: StringToHTML("<h3>Confirm to delete</h3>"),
      message: StringToHTML(`Are you sure do you want to delete CourtSport.`),
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            setIsLoading(true);
            const response = await deleteSportCourt(id).catch(console.error);
            getAllCourtsSports();
            setIsLoading(false);
            // Toast Start
            setShowToast(true);
            if (response.message === CourtSportDeleteSuccess) {
              setTMessage(CourtSportDeleteSuccess);
              setTVariant("success");
            } else {
              setTMessage(response.message);
              setTVariant("danger");
            }
            setTimeout(() => setShowToast(false), toastTimeStamp);
            // Toast End}
          },
        },
        { label: "No" },
      ],
    });
  };

  return (
    <>
      {isLoading && <Loader />}
      {showToast && (
        <ToastPopup isShow={showToast} variant={tVariant} message={tMessage} />
      )}
      <div className="manage-CourtSports">
        <h4>Manage Sports & Turfs</h4>
        <div className="row">
          <div className="col-lg-8 col-md-12 border-end py-4">
            <div className="table-responsive">
              <table className="table table-responsive table-secondary-subtle table-striped">
                <thead>
                  <tr>
                    <th>#</th>
                    <th scope="col">Turf Name</th>
                    <th scope="col">Sport Name</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {courtSports?.map((item, i) => (
                    <tr key={i} className="align-middle">
                      <td>{i + 1}</td>
                      <td>{item.CourtName}</td>
                      <td>{item.SportName}</td>
                      <td className="d-flex mx-auto">
                        <div className="mx-auto">
                          <Link
                            className="icon-edit me-0"
                            onClick={() => handleEdit(item)}
                          >
                            <i className="fas fa-pencil-alt ms-2"></i>
                          </Link>
                          <Link
                            className="icon-delete"
                            onClick={() => handleDelete(item.Id)}
                          >
                            <i className="fas fa-trash-alt ms-2"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3">
                <label htmlFor="CourtSportCourt" className="form-label">
                  Select Turf <span className="text-danger">*</span>:
                </label>
                <select
                  {...register("CourtId", { required: "Turf is required." })}
                  className="form-select mb-0"
                >
                  <option value="">Select Turf</option>
                  {courts?.map((item, i) => (
                    <option key={i} value={item.id}>
                      {item.court_name}
                    </option>
                  ))}
                </select>
                {errors.CourtId && (
                  <div className="small text-danger">
                    {errors.CourtId.message}
                  </div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="CourtSportCourt" className="form-label">
                  Select Sport <span className="text-danger">*</span>:
                </label>
                <select
                  {...register("SportId", { required: "Sport is required." })}
                  className="form-select mb-0"
                >
                  <option value="">Select Sport</option>
                  {sports?.map((item, i) => (
                    <option key={i} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {errors.SportId && (
                  <div className="small text-danger">
                    {errors.SportId.message}
                  </div>
                )}
              </div>
              <div className="d-flex mt-4">
                <div className="button--wrap">
                  <button
                    type="submit"
                    className="eg-btn btn--primary golf-btn mx-auto px-3 py-2"
                  >
                    {isAddCourtSport ? "Add" : "Update"}
                  </button>
                </div>
                <div className="button-wrap">
                  <button
                    className="eg-btn btn--primary golf-btn mx-auto px-3 py-2"
                    onClick={() => setIsAddCourtSport(true)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageCourtsSports;
