import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAdmin,
  getSingleCourt,
  getSingleSport,
  getSingleUser,
  phonePePayment,
} from "../../../services/ApiServices";
import Loader from "../../common/Loader";
import {
  MERCHANT_ID,
  SALT_INDEX,
  SALT_KEY,
} from "../../../constants/DataConstants";
import { ImagePath } from "../../../services/ShortFunctions";
import { formattedTime } from "../../Helper";
import { SHA256 } from "crypto-js";

export default function BookingSummary() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState([]);
  const [court, setCourt] = useState([]);
  const [sport, setSport] = useState([]);
  const [orgData, setOrgData] = useState([]);

  const {
    baseTotalPrice,
    finalTotalPrice,
    court_id,
    sport_id,
    booking_date,
    start_time,
    end_time,
  } = JSON.parse(localStorage.getItem("BookingDetails"));
  const id = JSON.parse(localStorage.getItem("loggedInUser"));

  useEffect(() => {
    window.scrollTo(0, 0);
    loadData();
    finalTotalPrice === 0 && baseTotalPrice === 0 && navigate("/booking-form");
    fetchOrgData();
  }, []);

  const fetchOrgData = async () => {
    try {
      const { data } = await getAdmin(1);
      setOrgData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const loadData = async () => {
    setIsLoading(true);
    try {
      const { data: userData } = await getSingleUser(id);
      const { data: courtData } = await getSingleCourt(court_id);
      const { data: sportData } = await getSingleSport(sport_id);
      setUser(userData);
      setCourt(courtData);
      setSport(sportData);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = () => {
    // const options = {
    //   method: "post",
    //   url: "https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay",
    //   headers: {
    //     accept: "application/json",
    //     "Content-Type": "application/json",
    //     "X-VERIFY": "d7a8e4458caa6fcd781166bbdc85fec76740c18cb9baa9a4c48cf2387d554180###1",
    //   },
    //   data: {
    //     request: "ewogICJtZXJjaGFudElkIjogIlBHVEVTVFBBWVVBVCIsCiAgIm1lcmNoYW50VHJhbnNhY3Rpb25JZCI6ICJNVDc4NTA1OTAwNjgxODgxMDQiLAogICJtZXJjaGFudFVzZXJJZCI6ICJNVUlEMTIzIiwKICAiYW1vdW50IjogMTAwMDAsCiAgInJlZGlyZWN0VXJsIjogImh0dHBzOi8vd2ViaG9vay5zaXRlL3JlZGlyZWN0LXVybCIsCiAgInJlZGlyZWN0TW9kZSI6ICJSRURJUkVDVCIsCiAgImNhbGxiYWNrVXJsIjogImh0dHBzOi8vd2ViaG9vay5zaXRlL2NhbGxiYWNrLXVybCIsCiAgIm1vYmlsZU51bWJlciI6ICI5OTk5OTk5OTk5IiwKICAicGF5bWVudEluc3RydW1lbnQiOiB7CiAgICAidHlwZSI6ICJQQVlfUEFHRSIKICB9Cn0=",
    //   },
    // };
    // axios
    //   .request(options)
    //   .then(function (response) {
    //     console.log(response.data);
    //   })
    //   .catch(function (error) {
    //     console.error(error);
    //   });

    let data = {
      PaymentMode: "Online",
      UserId: `${id}${Math.random().toString(8).slice(2).toUpperCase()}`,
      OtherCharges: 0,
      NetTotal: finalTotalPrice * 100,
      OrderStatus: "Order Confirmed",
      Contact: user.contact_number,
      FName: user.first_name || "Not Available",
      LName: user.last_name || "Not Available",
      Email: user.email_id || "myturf@gmail.com",
      MerchantTransactionId: Math.random().toString(36).slice(2).toUpperCase(),
    };
    payNow(data);
    // navigate("/booking-status");
  };

  const payNow = async (details) => {
    setIsLoading(true);
    let data = {
      merchantId: MERCHANT_ID,
      merchantTransactionId: details.MerchantTransactionId,
      merchantUserId: details.UserId,
      amount: details.NetTotal,
      redirectUrl: window.location.origin + "/booking-status",
      redirectMode: "REDIRECT",
      callbackUrl: window.location.origin + "/booking-status",
      mobileNumber: details.Contact,
      paymentInstrument: { type: "PAY_PAGE" },
    };

    const base64 = window.btoa(JSON.stringify(data));
    const xVerify =
      SHA256(base64 + "/pg/v1/pay" + SALT_KEY) + "###" + SALT_INDEX;
    const paymentResponse = await phonePePayment(base64, xVerify);
    console.log("🚀 ~ payNow ~ paymentResponse:", paymentResponse);

    // console.log("🚀 ~ payNow ~ paymentResponse:", paymentResponse);
    localStorage.setItem("paymentResponse", JSON.stringify(paymentResponse));

    if (paymentResponse.success === true) {
      window.location.href = String(
        paymentResponse.data.instrumentResponse.redirectInfo.url
      );
    }

    localStorage.setItem("xVerify", xVerify);

    localStorage.setItem("OnlineOrderDetails", JSON.stringify(details));
    localStorage.setItem("PaymentDetails", JSON.stringify(data));
    setIsLoading(false);
  };

  return (
    <div>
      {isLoading && <Loader />}
      <div className="play-and-membership">
        <div className="container-fluid g-0">
          <div className="facilities-wrapper container my-5 rounded-3 gap-5">
            <div className="facility-right two b-form card">
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <h3 className="mb-0">{court.court_name}</h3>
                  <h5 className="mb-0">{sport.name}</h5>
                </div>
                <div className="card-body">
                  {/* <div className="row mb-2">
                    <div className="col-1">
                      <i className="fa fa-location-arrow"></i>
                    </div>
                    <div className="col-11">
                      <h6 className="ms-3 text-dark">Address</h6>
                    </div>
                  </div> */}
                  <div className="row  mb-2">
                    <div className="col-1">
                      <i className="fa fa-calendar"></i>
                    </div>
                    <div className="col-11">
                      <h6 className="ms-3 text-dark">{booking_date}</h6>
                    </div>
                  </div>
                  <div className="row  mb-2">
                    <div className="col-1">
                      <i className="fa fa-money-bill"></i>
                    </div>
                    <div className="col-11">
                      <h6 className="ms-3 text-dark">₹ {finalTotalPrice}</h6>
                    </div>
                  </div>
                  <div className="row  mb-2">
                    <div className="col-1">
                      <i className="fa fa-clock"></i>
                    </div>
                    <div className="col-11">
                      <h6 className="ms-3 text-dark">
                        {formattedTime(start_time)} to {formattedTime(end_time)}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="card mt-5">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <span className="fw-bold text-dark">
                      Reschedule Policy :
                    </span>
                    <br />
                    Dolor eiusmod est est tempor id veniam dolore officia. Nisi
                    excepteur dolor sit ipsum quis adipisicing incididunt
                    occaecat aliquip ad. Id nostrud culpa tempor adipisicing ea
                    amet cupidatat.
                  </li>
                  <li className="list-group-item">
                    <span className="fw-bold text-dark">
                      Cancellation Policy :
                    </span>
                    <br />
                    Cillum occaecat sunt non dolor reprehenderit cupidatat.
                    Culpa qui irure aliqua sint. Ullamco mollit consectetur
                    laborum magna sit cillum consectetur mollit commodo dolor
                    ex.
                  </li>
                  <li className="list-group-item">
                    <span className="fw-bold text-dark">Club Policy :</span>
                    <br />
                    Sunt dolor cillum amet id enim ut in culpa. Ea occaecat aute
                    amet mollit sunt.
                  </li>
                  <li className="list-group-item">
                    <span className="fw-bold text-dark">
                      Terms of service :
                    </span>
                    <br />
                    By continuing, you agree to our{" "}
                    <a href="/" className="fw-bold">
                      terms of service.
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
            <div className="facility-right two b-form text-light card">
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  <h3 className="mb-0">Price Details</h3>
                </div>
                <div className="card-body">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between">
                      <span className="text-dark">Turf Price :</span>
                      <div className="fw-bold h5">₹ {finalTotalPrice}</div>
                    </li>
                    <li className="list-group-item">
                      <div className="d-flex justify-content-between">
                        <span className="text-dark">Convenience Fee :</span>
                        <div className="fw-bold h5">₹ 00.00</div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <span className="text-dark">Total Amount :</span>
                        <div className="fw-bold h5">₹ {finalTotalPrice}</div>
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="d-flex justify-content-between h3">
                        <div>₹ {finalTotalPrice}</div>
                        <div>₹ 00.00</div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <span className="text-dark">Advance Payable</span>
                        <div className="text-dark">To be paid at the venue</div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="card-footer py-3">
                  <div className="btn-block mb-sm-1 mb-md-0 d-flex justify-content-center">
                    <div className="membership-btn">
                      <a
                        onClick={() => onSubmit()}
                        style={{ zIndex: "auto" }}
                        className="text-white"
                      >
                        <i className="fa fa-money-check"></i>&nbsp; Proceed ₹{" "}
                        {finalTotalPrice}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <img
                  src={
                    orgData.org_logo
                      ? ImagePath(orgData.org_logo)
                      : "assets/img/logo.png"
                  }
                  className="img-fluid mt-5 rounded-5 w-50"
                  alt="logo"
                  style={{ maxWidth: 128 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
