import React, { useState, useEffect } from "react";
import {
  getTimeSlotsPricing,
  deleteTimeSlotPricing,
  updateTimeSlotPricingStatus,
  getOfferDuration,
  setOfferDuration,
} from "../../../services/ApiServices";
import {
  timeSlotPricingUpdatedSuccess,
  toastTimeStamp,
  timeSlotPricingDeleteSuccess,
} from "../../../constants/ResponseConstants";
import ToastPopup from "../../modals/ToastPopup";
import Loader from "../../common/Loader";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { ImagePath, StringToHTML } from "../../../services/ShortFunctions";
import ManageTimeSlotPricing from "../../modals/ManageTimeSlotPricing";

function ManageSlotsPricing() {
  const [timeSlotsPricing, setTimeSlotsPricing] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [d, setD] = useState();

  // Toast State Start
  const [showToast, setShowToast] = useState(false);
  const [tMessage, setTMessage] = useState();
  const [tVariant, setTVariant] = useState();
  // Toast State End

  // Modal Members & Functions Start
  const [show, setShow] = useState(false);
  const [mFor, setMFor] = useState();
  const handleClose = () => setShow(false);
  const handleShow = (m) => {
    setShow(true);
    setMFor(m);
  };
  // Modal Members & Functions End

  useEffect(() => {
    window.scrollTo(0, 0);
    loadTimeSlotsPricing();
    offerDuration();
  }, [show]);

  const loadTimeSlotsPricing = async () => {
    setIsLoading(true);
    const response = await getTimeSlotsPricing().catch(console.error);
    setTimeSlotsPricing(response.data);
    setIsLoading(false);
  };

  //Manage Turf Enable/Disable Status Start
  const handleStatus = async (isActive, name, id) => {
    try {
      const data = { isActive: isActive ? 0 : 1 };
      const response = await updateTimeSlotPricingStatus(data, id);

      // Toast Start
      setShowToast(true);
      if (response.message === timeSlotPricingUpdatedSuccess) {
        setTMessage(
          "<b>" +
            name +
            "</b> Time Slot Pricing Status is <b>" +
            (isActive ? "Disabled" : "Enabled") +
            "</b>."
        );
        setTVariant(isActive ? "danger" : "success");
      } else {
        setTMessage(response.message);
        setTVariant("danger");
      }
      setTimeout(() => setShowToast(false), toastTimeStamp);
      // Toast End
      loadTimeSlotsPricing();
    } catch (error) {}
  };
  //Manage Turf Enable/Disable Status End

  //Manage Turf Enable/Disable Status Start
  const handleDelete = (id, name) => {
    confirmAlert({
      title: StringToHTML(`<h3 className='text-one'>Confirm to delete</h3>`),
      message: StringToHTML(
        `Are you sure do you want to delete <span style='font-weight: bold; color: red;'>${name}</span> time slot pricing.`
      ),
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const response = await deleteTimeSlotPricing(id).catch(
              console.error
            );
            loadTimeSlotsPricing();
            // Toast Start
            setShowToast(true);
            if (response.message === timeSlotPricingDeleteSuccess) {
              setTMessage("<b>" + name + "</b> " + response.message);
              setTVariant("success");
            } else {
              setTMessage(response.message);
              setTVariant("danger");
            }
            setTimeout(() => setShowToast(false), toastTimeStamp);
            // Toast End
          },
        },
        { label: "No" },
      ],
    });
  };
  //Manage Turf Enable/Disable Status End

  const updateOfferDuration = async (value) => {
    const response = await setOfferDuration(value).catch(console.error);
    if (response.message === `${value} months duration set successfully.`) {
      offerDuration();
      // Toast Start
      setShowToast(true);
      if (response.message === timeSlotPricingDeleteSuccess) {
        setTMessage("<b>" + value + "</b> " + response.message);
        setTVariant("success");
      } else {
        setTMessage(response.message);
        setTVariant("success");
      }
      setTimeout(() => setShowToast(false), toastTimeStamp);
      // Toast End
    }
  };

  const offerDuration = async () => {
    try {
      const response = await getOfferDuration().catch(console.error);
      setD(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      {showToast && (
        <ToastPopup isShow={showToast} variant={tVariant} message={tMessage} />
      )}
      <div className="manage-TimeSlot" style={{ marginBottom: 80 }}>
        <div className="d-flex justify-content-lg-between">
          <h4>Manage Time Slot Pricing</h4>
          <div className="d-flex gap-3 align-items-center">
            <p className="mb-0" style={{ fontSize: 10, width: "162px" }}>
              Offer display for months
            </p>
            <select
              name="offer"
              className="form-select w-25"
              value={d}
              onChange={(e) => updateOfferDuration(e.target.value)}
            >
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>
            <div className="button--wrap button--wrap-two mt-0">
              <button
                type="button"
                className="eg-btn btn--primary golf-btn mx-auto py-2 px-3"
                style={{ width: "max-content" }}
                onClick={() => handleShow("Add")}
              >
                <i className="fas fa-plus ms-0 me-2"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 py-4">
            <div className="table-responsive">
              <table className="table table-responsive table-secondary-subtle table-striped">
                <thead>
                  <tr>
                    <th scope="col">Turf Name</th>
                    <th scope="col">Slot Name</th>
                    <th scope="col">Price Variation</th>
                    <th scope="col">Day Type</th>
                    <th scope="col">Special Day Date</th>
                    <th scope="col">Offer Image</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {timeSlotsPricing &&
                    timeSlotsPricing.map((item, i) => (
                      <tr key={i} className="align-middle">
                        <td>{item.court_name}</td>
                        <td>{item.SlotName}</td>
                        <td>{item.PriceVariation} %</td>
                        <td>{item.DayType}</td>
                        <td>{item.SpecialDayDate}</td>
                        <td>
                          <img
                            src={ImagePath(item.OfferImagePath)}
                            alt="Offer Banner"
                            style={{ minWidth: 100, maxWidth: "25%" }}
                          />
                        </td>
                        <td>
                          <div className="form-check form-switch d-flex justify-content-center">
                            <input
                              className="form-check-input bg-success border-success"
                              type="checkbox"
                              role="switch"
                              defaultChecked={
                                item.isActive === 1 ? true : false
                              }
                              onClick={() =>
                                handleStatus(
                                  item.isActive === 1 ? true : false,
                                  item.SlotName,
                                  item.id
                                )
                              }
                            />
                          </div>
                        </td>
                        <td>
                          <div className="d-flex justify-content-center">
                            {/* <Link
                              className="icon-edit me-0"
                              onClick={() => handleShow(item)}
                            >
                              <i className="fas fa-pencil-alt ms-2"></i>
                            </Link> */}
                            <Link
                              className="icon-delete"
                              onClick={() =>
                                handleDelete(item.id, item.SlotName)
                              }
                            >
                              <i className="fas fa-trash-alt ms-2"></i>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {show && (
        <ManageTimeSlotPricing
          show={show}
          handleClose={handleClose}
          mFor={mFor}
        />
      )}
    </>
  );
}

export default ManageSlotsPricing;
