import React, { useEffect } from "react";
import {
  getSingleUser,
  pinCode,
  updateUser,
} from "../../../services/ApiServices";
import { USER_ID } from "../../../constants/DataConstants";
import { set, useForm } from "react-hook-form";

export default function EditProfile() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
    getUserData();
  }, []);

  const getUserData = async () => {
    const response = await getSingleUser(USER_ID).catch(console.error);
    reset(response.data);
  };

  const onSubmit = async (data) => {
    const response = await updateUser(USER_ID, data).catch(console.error);
    reset(response.data);
    window.location.reload();
  };

  const getPinCode = async (pin) => {
    if (pin.length === 6) {
      const response = await pinCode(pin).catch(console.error);
      const res = response[0].PostOffice[0];
      setValue("city", res.Block);
      setValue("district", res.District);
      setValue("state", res.State);
    }
  };

  return (
    <div>
      <div className="office-information">
        <div className="contact-form edit-profile">
          <div className="user-img text-center mb-4">
            <img src="/assets/img/logo2.png" alt="" />
            <p>
              <i className="fas fa-pencil-alt"></i>
            </p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-6">
                <input
                  type="text"
                  placeholder="First Name"
                  {...register("first_name")}
                />
              </div>
              <div className="col-lg-6">
                <input
                  type="text"
                  placeholder="Last Name"
                  {...register("last_name")}
                />
              </div>
              <div className="col-lg-6">
                <input
                  type="text"
                  placeholder="+91 00 00 00 0000"
                  disabled
                  {...register("contact_number")}
                />
              </div>
              <div className="col-lg-6">
                <input
                  type="email"
                  placeholder="myname@email.com"
                  {...register("email_id")}
                />
              </div>
              <div className="col-lg-6">
                <input
                  type="text"
                  placeholder="Address Line"
                  {...register("address_line")}
                />
              </div>
              <div className="col-lg-6">
                <input
                  className="mb-0"
                  type="text"
                  placeholder="Pin/ZIP Code"
                  {...register("pin_code")}
                  onChange={(e) => getPinCode(e.target.value)}
                />
                <p className="note">
                  Kindly enter the PIN code first so that we can access details
                  about the city, district, and state.
                </p>
              </div>
              <div className="col-lg-4">
                <input type="text" placeholder="City" {...register("city")} />
              </div>
              <div className="col-lg-4">
                <input
                  type="text"
                  placeholder="District"
                  {...register("district")}
                />
              </div>
              <div className="col-lg-4">
                <input type="text" placeholder="State" {...register("state")} />
              </div>
            </div>
            <div className="button--wrap">
              <button
                type="submit"
                className="eg-btn btn--primary golf-btn mx-auto"
              >
                Save <i className="bi bi-arrow-right"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
